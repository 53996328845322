//TODO: is this RoleTypes enum necessary anymore? I think Ptoli's changes
//may have deprecated them.
export enum RoleTypes {
	//TODO old roles need to be removed?
	ADMIN = 'organizationAdmin',
	PRIOR_AUTH_REQUESTER = 'paRequester',
	DISABLE = 'disabled',
	CUSTOMER_UPDATES = 'customerUpdatesOnly',
	// portal specific roles
	PROVIDER_ADMIN = 'ProviderAdmin',
	PROVIDER_REPORT_VIEWER = 'ProviderReportViewer',
	PAYER_ADMIN = 'PayerAdmin',
	PAYER_REPORT_VIEWER = 'PayerReportViewer',
	DELEGATE_BENEFIT_MANAGER_ADMIN = 'DbmAdmin',
}

export interface RhymeUser {
	id: string;
	firstName: string;
	lastName: string;
	roles: string[];
	isConfirmed: boolean;
	userName?: string;
}

export enum MfaPreferenceStatus {
	NOT_SET = 'NOT_SET',
	EMAIL = 'EMAIL',
	TOTP = 'TOTP',
}
