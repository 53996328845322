import { EntityType, EntityGroup } from 'type-declarations';
import { setUpTOTP, updateMFAPreference, verifyTOTPSetup } from 'aws-amplify/auth';

export const EntityTypeActions = {
	isInEntityGroup(entityType: string, entityGroup: EntityGroup): boolean {
		return (this.toEntityGroup(entityType as EntityType) & entityGroup) !== 0;
	},
	toEntityGroup(entityType: EntityType): EntityGroup {
		switch (entityType) {
			case EntityType.PROVIDER_ORGANIZATION:
				return EntityGroup.ORGANIZATION;
			case EntityType.PAYER_INSTITUTION:
				return EntityGroup.PAYER_INSTITUTION;
			case EntityType.DELEGATED_BENEFIT_MANAGER:
				return EntityGroup.DELEGATED_BENEFIT_MANAGER;
		}
	},
};

export const getTotpSeedCode = async (): Promise<string> => {
	try {
		const totpSetupDetails = await setUpTOTP();
		return totpSetupDetails.sharedSecret;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.log(err);
		return '';
	}
};

export const verifyTotpCode = async (code: string): Promise<boolean> => {
	try {
		await verifyTOTPSetup({ code: code });
		await updateMFAPreference({ totp: 'PREFERRED' });
	} catch (_) {
		// Token is not verified
		return false;
	}
	return true;
};
