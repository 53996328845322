import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';
import { AccessScope, DefaultContact, RHYME_API_SERVICES } from 'type-declarations';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchProviderDefaultContact = createAsyncThunk(
	'provider-default-contact/fetchDefaultContact',
	async (_, { getState }) => {
		const appState: any = getState();
		const { scopeValue: organizationId } = appState.user.activeScope as AccessScope;
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/contact`,
			}).response;
			const data = (await body.json()) as unknown as DefaultContact;
			return data;
		} catch (error) {
			throw new Error(`Fetch default contact failed with error: ${error}`);
		}
	}
);

export const createProviderDefaultContact = createAsyncThunk(
	'provider-default-contact/createProviderDefaultContact',
	async ({ contact }: { contact: DefaultContact }, { getState, dispatch }) => {
		const appState: any = getState();
		const { scopeValue: organizationId } = appState.user.activeScope as AccessScope;
		const params = {
			body: {
				...contact,
			},
		};
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `organization/${organizationId}/contact`,
				options: params,
			}).response;
			dispatch(fetchProviderDefaultContact());
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to update default contact.',
				type: 'error',
			});
			throw new Error(`Create default contact failed with error: ${error}`);
		}
	}
);
