import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'aws-amplify/api';
import { RHYME_API_SERVICES, User, AccessScope } from 'type-declarations';
import { filterUserRoles, removeGlobalRoles, UserRolesFilter } from './filterUserRoles';
import { getAccessScopesFromUserRoles } from './utilities';
import { fetchPartners } from '../partner/thunks';
import { fetchAuthSession } from 'aws-amplify/auth';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchAndSetIdToken = createAsyncThunk<string>(
	'user/fetch_and_set_id_token',
	async (): Promise<string> => {
		const authSession = await fetchAuthSession();
		return authSession.tokens?.idToken?.toString() ?? '';
	}
);

export const fetchUserRoles = createAsyncThunk(
	'user/fetch_user_roles',
	async (userRolesFilters: UserRolesFilter[], { dispatch }): Promise<AccessScope[]> => {
		const { body } = await get({
			apiName: rhymeApiUrl,
			path: `authorization/user`,
		}).response;

		const data = (await body.json()) as User;
		filterUserRoles(data, userRolesFilters);
		const accessScopes = getAccessScopesFromUserRoles(data.userRoles);
		/**
		 * should only fetchPartner data for nonGlobal accessScope
		 */
		filterUserRoles(data, [removeGlobalRoles]);
		const nonGlobalAccessScope = getAccessScopesFromUserRoles(data.userRoles);
		if (accessScopes.length > 0 && nonGlobalAccessScope.length > 0) {
			dispatch(
				fetchPartners({
					partnerIds: nonGlobalAccessScope.map((a) => a.scopeValue),
				})
			);
		}
		return accessScopes;
	}
);
