import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get, post } from 'aws-amplify/api';
import {
	AddressChangeRequest,
	AddressCorrectionType,
	CaseModel,
	DataGatheringRequiredResponse,
	Provider,
	ProviderSelection,
	ProviderSelectionResponse,
	RHYME_API_SERVICES,
	SdgSubmit,
	SubmitData,
} from 'type-declarations';
import { toCamelCase } from 'string-utilities';
import { SnackbarManager } from 'rhyme-component-library';
import { RestApiResponse } from '@aws-amplify/api-rest/dist/esm/types';

const rhymeApiService = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 404];

export const fetchDataGatheringRequiredScreen = createAsyncThunk(
	'dg-required-thunk/fetchDataGatheringRequiredScreenThunk',
	async ({
		orgId,
		caseId,
		submissionId,
	}: {
		orgId: string;
		caseId: string;
		submissionId: string;
	}) => {
		try {
			const { body } = await get({
				apiName: rhymeApiService,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/required`,
			}).response;
			const data = await body.json();
			return data as unknown as DataGatheringRequiredResponse[];
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode } = error.response;
				throw new Error(
					`Response status of ${statusCode}, 
        dataGathering service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
				);
			}
			throw new Error(`Data gathering failed with error: ${error}`);
		}
	}
);

export const validateAndSubmitData = createAsyncThunk(
	'dg-required-thunk/validateAndSubmitData',
	async (
		{
			orgId,
			caseId,
			submissionId,
			submitData,
		}: {
			orgId: string;
			caseId: string;
			submissionId: string;
			submitData: SdgSubmit;
		},
		{ rejectWithValue }
	) => {
		try {
			const validateResponse = await validateSdg(
				orgId,
				caseId,
				submissionId,
				submitData.submittedData
			);
			if (!validateResponse || validateResponse.statusCode !== 200) {
				SnackbarManager.show({
					message: 'Failed to submit provider corrections.',
					type: 'error',
				});
				return rejectWithValue({
					status: validateResponse?.statusCode,
					message: validateResponse?.body.json(),
				});
			}
			const submitResponse = await submitSdg(orgId, caseId, submissionId, submitData);
			if (!submitResponse || submitResponse.statusCode !== 200) {
				SnackbarManager.show({
					message: 'Failed to submit provider corrections.',
					type: 'error',
				});
				return rejectWithValue({
					status: submitResponse?.statusCode,
					message: submitResponse?.body.json(),
				});
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
		}
	}
);

async function submitSdg(
	orgId: string,
	caseId: string,
	submissionId: string,
	submitData: SdgSubmit
): Promise<RestApiResponse | null> {
	const options = {
		body: submitData as unknown as FormData,
	};

	try {
		const response = await post({
			apiName: rhymeApiService,
			path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/submit`,
			options,
		}).response;
		return response as unknown as RestApiResponse;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn('Submit sdg failed with error:', error);
		return null;
	}
}

async function validateSdg(
	orgId: string,
	caseId: string,
	submissionId: string,
	correctionData: Partial<SubmitData>
): Promise<RestApiResponse | null> {
	const params = {
		body: { dataToValidate: correctionData } as unknown as FormData,
	};
	try {
		const response = await post({
			apiName: rhymeApiService,
			path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/validate`,
			options: params,
		}).response;
		return response as unknown as RestApiResponse;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn('Validate sdg failed with error:', error);
		return null;
	}
}

export const submitSdgData = createAsyncThunk(
	'dg-required-thunk/submitSdgDataThunk',
	async (
		{
			orgId,
			caseId,
			submissionId,
			submitData,
		}: {
			orgId: string;
			caseId: string;
			submissionId: string;
			submitData: SdgSubmit;
		},
		{ rejectWithValue }
	) => {
		try {
			const options = {
				body: submitData as unknown as FormData,
			};
			await post({
				apiName: rhymeApiService,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/submit`,
				options,
			}).response;
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to submit provider corrections.',
				type: 'error',
			});
			rejectWithValue(`Data gathering failed with error: ${error}`);
		}
	}
);

export const validateSdgData = createAsyncThunk(
	'dg-required-thunk/validateSdgData',
	async (
		{
			orgId,
			caseId,
			submissionId,
			correctionData,
		}: {
			orgId: string;
			caseId: string;
			submissionId: string;
			correctionData: Partial<SubmitData>;
		},
		{ rejectWithValue }
	) => {
		try {
			const params = {
				body: { dataToValidate: correctionData } as unknown as FormData,
			};
			await post({
				apiName: rhymeApiService,
				path: `organization/${orgId}/case/${caseId}/submission/${submissionId}/data-gathering/validate`,
				options: params,
			}).response;
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to submit provider corrections.',
				type: 'error',
			});

			rejectWithValue(`Data gathering validation with error: ${error}`);
		}
	}
);

interface ProviderFetchData {
	activeScopeId: string;
	submission: string;
	additionalSetSize: number;
	addressType: AddressCorrectionType | null;
}

export const fetchAddressOptions = createAsyncThunk<ProviderSelection[], ProviderFetchData>(
	'dg-required-thunk/fetchAddressOptions',
	async ({ activeScopeId, submission, additionalSetSize, addressType }, { getState }) => {
		const appState = getState() as any;
		const { providerSearchTerms } = appState.dataGathering;
		const { case: caseDetails } = appState.case;
		const providerType = caseDetails.addressCorrections[0]?.addressType ?? addressType;
		const providerTypeCamelCase = toCamelCase(providerType);
		const preexistingProvider = caseDetails.referral[providerTypeCamelCase];
		const isRenderingFacility: boolean = providerType === AddressCorrectionType.RenderingFacility;
		const providerTypeString = isRenderingFacility ? 'facility' : 'provider';
		const params = createParams(
			additionalSetSize,
			providerSearchTerms,
			preexistingProvider,
			false,
			false
		);
		//First call********
		try {
			let providerSelections = await providerSelectionsNetworkCall(
				activeScopeId,
				caseDetails,
				submission,
				providerTypeString,
				params
			);
			//Second call *********
			if (providerSelections.length === 0) {
				if (isRenderingFacility) {
					const params = createParams(
						additionalSetSize,
						providerSearchTerms,
						preexistingProvider,
						false,
						true
					);
					providerSelections = await providerSelectionsNetworkCall(
						activeScopeId,
						caseDetails,
						submission,
						providerTypeString,
						params
					);
				}
				//Third call ********
				if (providerSelections.length === 0) {
					const tryWithAllOrgs = isRenderingFacility ? true : false;
					const params = createParams(
						additionalSetSize,
						providerSearchTerms,
						preexistingProvider,
						true,
						tryWithAllOrgs
					);
					providerSelections = await providerSelectionsNetworkCall(
						activeScopeId,
						caseDetails,
						submission,
						providerTypeString,
						params
					);
				}
			}
			return providerSelections;
		} catch (error) {
			throw new Error(`Fetch address option failed with error: ${error}`);
		}
	}
);

const providerSelectionsNetworkCall = async (
	activeScopeId: string,
	caseDetails: CaseModel,
	submissionId: string,
	providerType: string,
	params: any
): Promise<ProviderSelection[]> => {
	// eslint-disable-next-line max-len
	const getUrl = `organization/${activeScopeId}/case/${caseDetails.caseId}/submission/${submissionId}/payer-integration/${caseDetails.benefitManager.toLowerCase()}/${providerType}-selection`;

	const { body } = await get({
		apiName: rhymeApiService,
		path: getUrl,
		options: {
			queryParams: {
				...params,
			},
		},
	}).response;
	const data = (await body.json()) as unknown as ProviderSelectionResponse;
	return data.providerSelections as ProviderSelection[];
};

const createParams = (
	additionalSetSize: number,
	searchTerms: Partial<AddressChangeRequest>,
	preexistingProvider: Provider,
	tryWithoutTin: boolean,
	searchAllOrganizationTypes: boolean
): {
	[k: string]: string;
} => {
	const resultSetSize = 50;
	const resultOffSet = (): number => (0 + resultSetSize) * additionalSetSize;
	const params: AddressChangeRequest = {
		npi: searchTerms?.npi || preexistingProvider?.npi,
		address: preexistingProvider?.address1,
		firstName: preexistingProvider['firstName'] || undefined,
		lastName: preexistingProvider['lastName'] || undefined,
		name: preexistingProvider['facilityName'] || preexistingProvider['name'] || undefined,
		taxId:
			searchTerms?.npi || searchTerms?.taxId || tryWithoutTin
				? searchTerms?.taxId
				: preexistingProvider['taxId'],
		searchAllOrganizationTypes: searchAllOrganizationTypes,
		offset: resultOffSet(),
		limit: resultSetSize,
		city: searchTerms?.city || undefined,
		state: searchTerms?.state || undefined,
		zip: searchTerms?.zip || undefined,
	};
	return Object.fromEntries(
		Object.entries(params)
			.filter(([key, value]) => value !== undefined)
			.map(([key, value]) => [key, String(value)])
	);
};
