import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'axios';
import {
	clearSessionStorage,
	getSessionStorage,
	saveMetadataConformanceResults,
} from './utils/vendorAuthUtils';
import {
	tokenPayload,
	getMrnCaseLookupRequestParams,
	getPanRsoRequestParams,
} from './utils/accessTokenAndCaseUtils';
import { EpicAuthData, EpicFhirState, EpicPatient, EpicPractitioner } from './utils/types';
import {
	setupAccessTokenParams,
	TokenRequestData,
	setEpicAuthResponse,
	setEpiPatient,
	setEpicPractioner,
} from 'state-services';

/**
 * @description Thunk that kicks off the validation, case listing and/or redirecxt to Rhyme React Tachyon.
 * @param {Object} getVendorAuth Thunk params object
 * @param {string} getVendorAuth.iss Query param value provided by the URL string that redirected the
 * user from Epic to Rhymes Smart on FHIR application
 * @param {string} getVendorAuth.launchCode Query param value provided by the URL string that redirected the
 * user from Epic to Rhymes Smart on FHIR application
 * @param {string} getVendorAuth.clientId Epic FHIR applications platform ID
 * @param {string} getVendorAuth.redirectUrl Epic FHIR applications redirect URL
 * @param {boolean} getVendorAuth.isEmpa EMPA workflow status
 * @returns
 */
export const getVendorAuth = createAsyncThunk(
	'epic-fhir/getVendorAuth',
	async (
		{
			iss,
			launchCode,
		}: {
			iss: string;
			launchCode: string;
		},
		{ dispatch, rejectWithValue }
	) => {
		clearSessionStorage();
		const { data } = await Axios.get(`${iss}/metadata`).then((data) => data);
		if (!data) return rejectWithValue('Error retrieving Fhir server metadata information.');
		saveMetadataConformanceResults(data, iss, launchCode);

		return data;
	}
);

/**
 * @description Handles bulk of Epic authentication, data gathering, & requesting RSO link(s) for redirect.
 * @param {string} tokenExchangeUri Epic FHIR applications redirect URL
 * @param {TokenRequestData} tokenRequestData EMPA workflow status
 * @returns {string} Redirect URL
 */
export const getRsoRedirtectOrCases = createAsyncThunk(
	'epic-fhir/getRsoRedirtectOrCases',
	async (
		{
			tokenExchangeUri,
			tokenRequestData,
		}: {
			tokenExchangeUri: string;
			tokenRequestData: TokenRequestData;
		},
		{ getState, dispatch, rejectWithValue }
	) => {
		// Request for receiving the epic token URL to request against.
		const { data: epicTokenExchangeData } = await Axios.post(
			`${tokenExchangeUri}`,
			tokenRequestData
		);
		if (!epicTokenExchangeData) return rejectWithValue('Epic token exchange failed');

		// Request information from EPIC using the token url, response information is used for fetching
		const authExchangeParams = setupAccessTokenParams(epicTokenExchangeData, tokenRequestData);
		const { data: epicAuthResData } = await Axios.post(
			`${tokenRequestData.tokenUrl}`,
			authExchangeParams,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);
		if (epicAuthResData?.is_empa && !epicAuthResData?.X12TRN02)
			return rejectWithValue('Epic response missing X12TRN02 value required for EMPA launch');
		else dispatch(setEpicAuthResponse(epicAuthResData));

		// Requests patient & practitioner resources from Epic FHIR API.
		const { baseUrl } = getSessionStorage();
		const payload = tokenPayload(epicAuthResData.id_token);
		const [epicPatientResponse, epicPractitionerResponse] = await Promise.all([
			await Axios.get<EpicPatient>(`${baseUrl}/Patient/${epicAuthResData.patient}`, {
				headers: {
					Authorization: `${epicAuthResData.token_type} ${epicAuthResData.access_token}`,
				},
			}),
			await Axios.get<EpicPractitioner>(payload.fhirUser, {
				headers: { Authorization: `${epicAuthResData.token_type} ${epicAuthResData.access_token}` },
			}),
		]);
		const epicPatient: EpicPatient = epicPatientResponse?.data;
		const epicPractitioner: EpicPractitioner = epicPractitionerResponse?.data;
		if (!epicPatient || !epicPractitioner)
			return rejectWithValue('Epic patient or practioner data missing');
		else {
			dispatch(setEpiPatient(epicPatient));
			dispatch(setEpicPractioner(epicPractitioner));
		}

		if (epicAuthResData.X12TRN02)
			dispatch(getRsoLink({})); // EMPA, redirect to RSO link
		else dispatch(getMrnCaseLookup({ epicAuthResData, epicPatient })); // Non-EMPA, display case list
	}
);

export const getRsoLink = createAsyncThunk(
	'epic-fhir/getRsoLink',
	async ({ selectedCaseId }: { selectedCaseId?: string }, { getState, rejectWithValue }) => {
		const { epicFhir }: { epicFhir: EpicFhirState } = getState() as any;
		const { epicAuthResponse, epicPractitioner } = epicFhir;
		if (epicAuthResponse && epicPractitioner) {
			const params = getPanRsoRequestParams(
				epicFhir,
				epicAuthResponse,
				epicPractitioner,
				selectedCaseId
			);
			const caseSearchUrl = process.env.REACT_APP_API_CASE_SEARCH;
			const { data: url } = await Axios.post(`${caseSearchUrl}/v3/generateLink`, params, {
				headers: {
					Authorization: `${epicAuthResponse.id_token}`,
				},
			});
			if (!url) return rejectWithValue('Epic patient or practioner data missing');
			return url;
		}
		return '';
	}
);

export const getMrnCaseLookup = createAsyncThunk(
	'epic-fhir/getMrnCaseLookup',
	async (
		{ epicAuthResData, epicPatient }: { epicAuthResData: EpicAuthData; epicPatient: EpicPatient },
		{ getState, rejectWithValue }
	) => {
		const { epicFhir }: { epicFhir: EpicFhirState } = getState() as any;
		const caseSearchUrl = process.env.REACT_APP_API_CASE_SEARCH;
		const params = getMrnCaseLookupRequestParams(epicFhir, epicPatient);

		// Config might nee updated. Leaving empty for now.
		const res = await Axios.post(`${caseSearchUrl}/v3/mrnCaseLookup/`, params, {
			headers: {
				Authorization: `${epicAuthResData.id_token}`,
			},
		});
		if (!res.data || res.data?.totalCount === 0)
			return rejectWithValue('No open cases were found for the patient MRN identification given.');

		return res.data.documents;
	}
);
