import { AsyncThunk } from '@reduxjs/toolkit';
import { RhymeUser } from 'type-declarations';

export interface CrudDialogState {
	isDialogOpen: boolean;
	crudType: CrudType;
	confirmAction: AsyncThunk<any, { user: RhymeUser }, any>;
	isSubmitting: boolean;
}

export enum CrudType {
	Create = 'Create',
	Read = 'Read',
	Update = 'Update',
	Delete = 'Delete',
	Reset = 'Reset',
}
