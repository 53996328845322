import {
	IconButton,
	styled,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Typography,
} from '@mui/material';
import React, { FC } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const AuthAppToolTip: FC = () => {
	const toolTipText = (
		<>
			<Typography fontWeight={'fontWeightBold'} sx={{ textDecoration: 'underline' }}>
				What is an Authenticator App?
			</Typography>
			<br />
			<Typography variant="body1" fontWeight="light">
				An authenticator app is an application on your computer or mobile device that generates a
				time-based, one-time password (TOTP). Rhyme uses TOTPs as part of our multi-factor
				authentication strategy.
			</Typography>
			<br />
			<Typography variant="body1" fontWeight="light">
				Authenticator apps pair with a site (like Rhyme) via a site-generated key. Once paired, both
				the site and the app share an understanding of the specific TOTP to be used during login.
			</Typography>
			<br />
			<Typography variant="body1" fontWeight="light">
				Follow the steps on the screen to link your preferred authenticator app to your Rhyme
				account. Examples of authenticator apps include Google Authenticator, Microsoft
				Authenticator, and 1Password.
			</Typography>
		</>
	);

	const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			padding: 25,
			maxWidth: 600,
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
		},
	});

	return (
		<CustomTooltip title={toolTipText} placement="right-start">
			<IconButton>
				<HelpOutlineIcon />
			</IconButton>
		</CustomTooltip>
	);
};
