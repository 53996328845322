import { PartnerEntity } from 'type-declarations';
import { useAppSelector } from './useReduxToolkit';
import { RootState } from '@app/store';
import { selectPartnerEntityGivenScope } from 'state-services';

export function useActivePartnerEntity(): PartnerEntity {
	return useAppSelector((state: RootState) =>
		selectPartnerEntityGivenScope(state.partner, state.user.activeScope)
	);
}
