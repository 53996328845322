import { createSlice } from '@reduxjs/toolkit';
import { Organization, SliceStatus, TermsOfService } from 'type-declarations';
import { fetchTermsOfService, fetchUserTermsStatus, postUserTermsStatus } from './thunks';

export interface OrganizationState {
	status: SliceStatus;
	termsOfService: TermsOfService | null;
	accepted: boolean | null;
}

const initialState: OrganizationState = {
	status: SliceStatus.IDLE,
	termsOfService: null,
	accepted: null,
};

export const organizationSlice = createSlice({
	name: 'organizationThunk',
	initialState,
	reducers: {
		resetOrganizationSlice: (state) => {
			Object.assign(state, initialState);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchTermsOfService.pending, (state) => {
			state.status = SliceStatus.PENDING;
		});
		builder.addCase(fetchTermsOfService.fulfilled, (state, action) => {
			state.status = SliceStatus.SUCCEEDED;
			state.termsOfService = action.payload;
		});
		builder.addCase(fetchTermsOfService.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
		builder.addCase(fetchUserTermsStatus.pending, (state) => {
			state.status = SliceStatus.PENDING;
		});
		builder.addCase(fetchUserTermsStatus.fulfilled, (state, action) => {
			state.status = SliceStatus.SUCCEEDED;
			state.accepted = action.payload;
		});
		builder.addCase(fetchUserTermsStatus.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
		builder.addCase(postUserTermsStatus.pending, (state) => {
			state.status = SliceStatus.PENDING;
		});
		builder.addCase(postUserTermsStatus.fulfilled, (state) => {
			state.accepted = true;
		});
		builder.addCase(postUserTermsStatus.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
	},
});

export const organizationReducer = organizationSlice.reducer;
export const organizationAction = organizationSlice.actions;
export const { resetOrganizationSlice } = organizationAction;
