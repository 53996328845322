import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AccessScope,
	getEntityTypeForScopeKey,
	IdTokenPayload,
	ScopeKeyOptions,
	ScopeKeyValuePair,
	SliceStatus,
} from 'type-declarations';
import { fetchUserRoles, fetchAndSetIdToken } from './';
import { getActiveAccessScope, getIdTokenPayloadFromIdTokenJwt } from './utilities';
import * as Sentry from '@sentry/react';

export const userInitialState: UserState = {
	userDetails: {} as IdTokenPayload,
	activeScope: {} as AccessScope,
	accessScopes: [],
	idToken: '',
	isRso: false,
	status: SliceStatus.IDLE,
};

export interface UserState {
	userDetails: IdTokenPayload;
	activeScope: AccessScope;
	accessScopes: AccessScope[];
	idToken: string;
	isRso: boolean; // rso signin
	status: SliceStatus;
}

function setUserStateWithIdToken(state: UserState, idToken: string): void {
	state.idToken = idToken;
	const jwtBody = getIdTokenPayloadFromIdTokenJwt(state.idToken);
	state.userDetails = jwtBody;
	Sentry.setUser({
		email: jwtBody.email,
		give_name: jwtBody.given_name,
		last_name: jwtBody.family_name,
		full_name: `${jwtBody.given_name} ${jwtBody.family_name}`,
	});
}

function setActiveSentryTags(state: UserState): void {
	Sentry.setTag('orgID', state.activeScope.scopeValue);
	Sentry.setTag('activePartnerEntityId', state.activeScope.scopeValue);
	Sentry.setTag(
		'activePartnerEntityType',
		getEntityTypeForScopeKey(state.activeScope.scopeKey as ScopeKeyOptions)
	);
}

export const userSlice = createSlice({
	name: 'user',
	initialState: userInitialState,
	reducers: {
		logOut: (state: UserState) => {
			Object.assign(state, userInitialState);
		},
		updateActiveScope: (state: UserState, action: PayloadAction<ScopeKeyValuePair>) => {
			const { scopeKey, scopeValue } = action.payload;
			state.activeScope = state.isRso
				? { scopeKey, scopeValue, roles: [], capabilities: [] }
				: (state.accessScopes.find((s) => s.scopeValue === scopeValue && s.scopeKey === scopeKey) ??
					state.activeScope);
			setActiveSentryTags(state);
		},
		isRsoUser: (state: UserState, action: PayloadAction<boolean>) => {
			state.isRso = action.payload;
		},
		setIdToken: (state: UserState, action: PayloadAction<string>) => {
			setUserStateWithIdToken(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserRoles.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		});
		builder.addCase(fetchUserRoles.fulfilled, (state, action: PayloadAction<AccessScope[]>) => {
			const accessScopes = action.payload;
			state.status = SliceStatus.SUCCEEDED;
			state.accessScopes = accessScopes;
			state.activeScope = getActiveAccessScope(accessScopes);
			state.isRso = false;
			setActiveSentryTags(state);
		});
		builder.addCase(fetchUserRoles.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
		builder.addCase(fetchAndSetIdToken.rejected, (state) => {
			state.idToken = 'ERROR';
		});
		builder.addCase(fetchAndSetIdToken.fulfilled, (state, action: PayloadAction<string>) => {
			setUserStateWithIdToken(state, action.payload);
		});
	},
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
export const { logOut, updateActiveScope, isRsoUser, setIdToken } = userActions;
