import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { RhymeUser, SliceStatus, tryGetFirstStandardResponseError } from 'type-declarations';
import {
	createPartnerUser,
	deletePartnerUser,
	fetchPartnerUsers,
	resetMfaDeviceToEmail,
	updatePartnerUser,
} from './thunks';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { CrudDialogState, CrudType } from './models';
import { SnackbarManager } from 'rhyme-component-library';

export interface PartnerUsersState {
	status: SliceStatus;
	users: RhymeUser[];
	selectedRow: GridRowSelectionModel;
	dialogs: CrudDialogState[];
}

const initialState: PartnerUsersState = {
	status: SliceStatus.IDLE,
	users: [],
	selectedRow: [],
	dialogs: [
		{
			isDialogOpen: false,
			crudType: CrudType.Create,
			confirmAction: createPartnerUser,
			isSubmitting: false,
		},
		{
			isDialogOpen: false,
			crudType: CrudType.Update,
			confirmAction: updatePartnerUser,
			isSubmitting: false,
		},
		{
			isDialogOpen: false,
			crudType: CrudType.Delete,
			confirmAction: deletePartnerUser,
			isSubmitting: false,
		},
		{
			isDialogOpen: false,
			crudType: CrudType.Reset,
			confirmAction: resetMfaDeviceToEmail,
			isSubmitting: false,
		},
	],
};

const setIsDialogOpenByCrudType = (
	state: PartnerUsersState,
	crudType: CrudType,
	isDialogOpenValue: boolean
): void => {
	const dialogIndex = state.dialogs.findIndex((d) => d.crudType === crudType);
	dialogIndex > -1 ? (state.dialogs[dialogIndex].isDialogOpen = isDialogOpenValue) : null;
};

const setIsSubmittingByCrudType = (
	state: PartnerUsersState,
	crudType: CrudType,
	isSubmittingValue: boolean
): void => {
	const dialogIndex = state.dialogs.findIndex((d) => d.crudType === crudType);
	dialogIndex > -1 ? (state.dialogs[dialogIndex].isSubmitting = isSubmittingValue) : null;
};

const filterOutUsersWithNoIds = (users: RhymeUser[]): RhymeUser[] =>
	users.filter((user) => user.id && user.id.length > 0);

export const partnerUsersSlice = createSlice({
	name: 'partner-users',
	initialState,
	reducers: {
		resetPartnerUsers: (state) => {
			Object.assign(state, initialState);
		},
		selectRow: (state, action) => {
			state.selectedRow = action.payload;
		},
		openPartnerUsersDialog: (state, action) => {
			setIsDialogOpenByCrudType(state, action.payload.crudType, true);
		},
		closePartnerUsersDialog: (state, action) => {
			setIsDialogOpenByCrudType(state, action.payload.crudType, false);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchPartnerUsers.pending, (state) => {
			if (state.status === SliceStatus.IDLE) {
				state.status = SliceStatus.PENDING;
			} else state.status = SliceStatus.REFRESHING;
		});
		builder.addCase(fetchPartnerUsers.fulfilled, (state, action) => {
			state.status = SliceStatus.SUCCEEDED;
			state.users = filterOutUsersWithNoIds(action.payload);
		});
		builder.addCase(fetchPartnerUsers.rejected, (state) => {
			state.status = SliceStatus.REJECTED;
		});
		//Create
		builder.addCase(createPartnerUser.pending, (state) => {
			setIsSubmittingByCrudType(state, CrudType.Create, true);
		});
		builder.addCase(createPartnerUser.rejected, (state, action) => {
			let errorMessage = 'User create failed.';
			if (isRejectedWithValue(action)) {
				errorMessage = tryGetFirstStandardResponseError(action.payload) ?? errorMessage;
			}
			SnackbarManager.show({ message: errorMessage, type: 'error' });
			setIsSubmittingByCrudType(state, CrudType.Create, false);
		});
		builder.addCase(createPartnerUser.fulfilled, (state, action) => {
			SnackbarManager.show({ message: 'User added.', type: 'success' });
			state.users = filterOutUsersWithNoIds(action.payload);
			const newUserName = action.meta.arg.user.userName ?? '';
			const newUserId = state.users.find((u) => u.userName === newUserName)?.id ?? '';
			if (newUserId) {
				state.selectedRow = [newUserId];
			}
			setIsDialogOpenByCrudType(state, CrudType.Create, false);
			setIsSubmittingByCrudType(state, CrudType.Create, false);
		});
		//Update
		builder.addCase(updatePartnerUser.pending, (state) => {
			setIsSubmittingByCrudType(state, CrudType.Update, true);
		});
		builder.addCase(updatePartnerUser.rejected, (state, action) => {
			let errorMessage = 'User update failed.';
			if (isRejectedWithValue(action)) {
				errorMessage = tryGetFirstStandardResponseError(action.payload) ?? errorMessage;
			}
			SnackbarManager.show({ message: errorMessage, type: 'error' });
			setIsSubmittingByCrudType(state, CrudType.Update, false);
		});
		builder.addCase(updatePartnerUser.fulfilled, (state, action) => {
			state.users = filterOutUsersWithNoIds(action.payload);
			SnackbarManager.show({ message: 'User updated', type: 'success' });
			setIsDialogOpenByCrudType(state, CrudType.Update, false);
			setIsSubmittingByCrudType(state, CrudType.Update, false);
		});
		//Delete
		builder.addCase(deletePartnerUser.pending, (state) => {
			setIsSubmittingByCrudType(state, CrudType.Delete, true);
		});
		builder.addCase(deletePartnerUser.fulfilled, (state, action) => {
			state.selectedRow = [];
			state.users = filterOutUsersWithNoIds(action.payload);
			SnackbarManager.show({ message: 'User deleted.', type: 'success' });
			setIsDialogOpenByCrudType(state, CrudType.Delete, false);
			setIsSubmittingByCrudType(state, CrudType.Delete, false);
		});
		builder.addCase(deletePartnerUser.rejected, (state) => {
			SnackbarManager.show({
				message: 'Failed to delete user.',
				type: 'error',
			});
			setIsSubmittingByCrudType(state, CrudType.Delete, false);
		});
		//reset mfa device to email
		builder.addCase(resetMfaDeviceToEmail.pending, (state) => {
			setIsSubmittingByCrudType(state, CrudType.Reset, true);
		});
		builder.addCase(resetMfaDeviceToEmail.fulfilled, (state) => {
			SnackbarManager.show({ message: "The user's MFA app has been reset.", type: 'success' });
			setIsSubmittingByCrudType(state, CrudType.Reset, false);
		});
		builder.addCase(resetMfaDeviceToEmail.rejected, (state) => {
			SnackbarManager.show({
				message: "Failed to reset the user's MFA app.",
				type: 'error',
			});
			setIsSubmittingByCrudType(state, CrudType.Reset, false);
		});
	},
});

export const partnerUsersReducer = partnerUsersSlice.reducer;
export const partnerUsersActions = partnerUsersSlice.actions;
export const { resetPartnerUsers, selectRow, openPartnerUsersDialog, closePartnerUsersDialog } =
	partnerUsersActions;
