import {
	Extension2,
	MetadataConformanceStatement,
	FhirSessionStorage,
	FhirQueryParams,
	UrlExtension,
} from './types';

export const setSessionStorage = (
	state: string,
	tokenUrl: string,
	authUrl: string,
	iss: string,
	clientId: string,
	launchCode: string,
	redirectUrl: string
): void => {
	sessionStorage.setItem('state', state);
	sessionStorage.setItem(`${state}_tokenUrl`, tokenUrl);
	sessionStorage.setItem(`${state}_authUrl`, authUrl);
	sessionStorage.setItem(`${state}_baseUrl`, iss);
	sessionStorage.setItem(`${state}_clientId`, clientId || '');
	sessionStorage.setItem(`${state}_launchCode`, launchCode || '');
	sessionStorage.setItem(`${state}_redirectUrl`, redirectUrl);
};

export const getSessionStorage = (): FhirSessionStorage => {
	const state = sessionStorage.getItem('state') ?? '';
	return {
		state,
		authUrl: sessionStorage.getItem(`${state}_authUrl`) ?? '',
		baseUrl: sessionStorage.getItem(`${state}_baseUrl`) ?? '',
		tokenUrl: sessionStorage.getItem(`${state}_tokenUrl`) ?? '',
		clientId: sessionStorage.getItem(`${state}_clientId`) ?? '',
		launchCode: sessionStorage.getItem(`${state}_launchCode`) ?? '',
		redirectUrl: sessionStorage.getItem(`${state}_redirectUrl`) ?? '',
	};
};

export const clearSessionStorage = (): void => {
	sessionStorage.clear();
};

export const getQueryParams = (): FhirQueryParams => {
	const url = new URL(window.location.href);
	return {
		iss: url.searchParams.get('iss') || '',
		launchCode: url.searchParams.get('launch') || '',
		urlSearchParams: url.searchParams,
	};
};

/**
 * @param {MetadataConformanceStatement} conformanceStatement
 * @param {string} iss
 * @param {string} launchCode
 * @param {string} clientId
 * @param {string} redirectUrl
 */
export const saveMetadataConformanceResults = (
	conformanceStatement: MetadataConformanceStatement,
	iss: string,
	launchCode: string
): void => {
	const { stateId: state } = generateStateValue(launchCode.substring(0, 32));
	const redirectUrl = process.env.REACT_APP_REDIRECT_URL || '';
	const tokenUrl = findEndpointFor('token', conformanceStatement);
	const authUrl = findEndpointFor('authorize', conformanceStatement);
	const clientId = iss.includes('vendorservice')
		? process.env.REACT_APP_EMPA_CLIENT_ID
		: process.env.REACT_APP_CLIENT_ID;

	setSessionStorage(state, tokenUrl, authUrl, iss, clientId || '', launchCode, redirectUrl);
	saveLaunchParameters(state);
};

/**
 * @param {string} endpointName
 * @param {MetadataConformanceStatement} conformanceStatement
 * @returns {string}
 */
export const findEndpointFor = (
	endpointName: string,
	conformanceStatement: MetadataConformanceStatement
): string => {
	const secExtensions = getSecurityExtensions(conformanceStatement);
	const endpoint = getEndpointFor(endpointName, secExtensions);
	return endpoint?.valueUri ?? '';
};

/**
 * @param {MetadataConformanceStatement} conformanceStatement
 * @returns {Extension2}
 */
export const getSecurityExtensions = (
	conformanceStatement: MetadataConformanceStatement
): Array<Extension2> => {
	return conformanceStatement.rest
		.flatMap((sec: any) => sec.security)
		.flatMap((ext: any) => ext.extension)
		.flatMap((ext2: any) => ext2.extension);
};

/**
 * @param {string} name Key value for UrlExtension to be returned
 * @param {UrlExtension[]} urlExtensions Array of UrlExtensions return from Epic service
 * @returns {UrlExtension} UrlExtension object containing the necessary URI
 */
export const getEndpointFor = (
	name: string,
	urlExtensions: UrlExtension[]
): UrlExtension | undefined => {
	return urlExtensions.find((x: any) => x.url === name);
};

/**
 * @description Generates a unique string ID to preface session storage values.
 * @param {string} statusText Launch code sub-string
 * @returns {string} unique string ID
 */
export const generateStateValue = (statusText: string): { stateId: string; time: string } => {
	const now: Date = new Date();
	const time = new Date(now).getTime().toString();
	return { stateId: time + statusText, time };
};

/**
 * @description Saves required data to session storage so it can be consumed after redirect occurs.
 * @param {string} state ID used for accessing session storage information
 */
const saveLaunchParameters = (state: string): void => {
	const { authUrl } = getSessionStorage();
	epicRedirect(authUrl, redirectParameters());
};

/**
 * @description Creates a new redirect query param object.
 * @param {string} state Unique session id
 * @returns {URLSearchParams}
 */
export const redirectParameters = (): URLSearchParams => {
	const params = new URLSearchParams();
	const storage = getSessionStorage();

	params.append('scope', 'launch openid fhirUser patient/*.read');
	params.append('response_type', 'code');
	params.append('client_id', storage.clientId);
	params.append('redirect_uri', storage.redirectUrl);
	params.append('launch', storage.launchCode);
	params.append('aud', storage.baseUrl);
	params.append('state', storage.state);

	return params;
};

/**
 * @description Based on information from Epic, function redirects to the fhir authorize url.
 * @param {string} url Epic FHIR authorize URL
 * @param {URLSearchParams} params Query params object
 */
export const epicRedirect = (url: string, params: URLSearchParams): void => {
	const redirectLink = `${url}?${params}`;
	self.location.href = redirectLink;
};
