import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { Navigate, useLocation } from 'react-router-dom';
import { SnackbarManager } from 'rhyme-component-library';
import { UserState } from 'state-services';

// wrapper for restricted routes that require certain capability permissions
export const PrivateRoute = ({
	children,
	capabilitiesWithPermission,
}: {
	children: JSX.Element; // requested component route
	capabilitiesWithPermission: string[]; // required capabilities permissions to access route
}): JSX.Element => {
	const location = useLocation();
	const { activeScope } = useAppSelector<UserState>((state) => state.user);

	//validate required capabilities are included in the activeScope capabilities
	const isAuthorized = capabilitiesWithPermission.filter((r) =>
		activeScope.capabilities.includes(r)
	);

	/**
	 * if activeScope capabilities
	 * dont match the required capabilities permission
	 * send to landing page
	 */
	if (!isAuthorized) {
		SnackbarManager.showBeforeNavigation(
			'You do not have the permission(s) to view that page',
			'error'
		);

		return <Navigate to="/" state={{ from: location }} />;
	}

	// else continue to requested route
	return children;
};
