import { Button, Dialog, TextField, InputAdornment, Grid2 as Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { GridColDef, DataGrid } from '@mui/x-data-grid';
import { fetchProviderFacilityRule, setFacilityRule } from 'state-services';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import EditFacility from './EditFacility';
import AddIcon from '@mui/icons-material/Add';
import './facilityRules.scss';
import SearchIcon from '@mui/icons-material/Search';
import { isStatusPendingOrRefreshing } from 'slice-utilities';
import { FacilityRule, RenderingFacility } from 'type-declarations';

const FacilityRules: FC = () => {
	const dispatch = useAppDispatch();
	const { facilities, status: facilityRulesStatus } = useAppSelector(
		(state) => state.admin.provider.facilityRules
	);
	const { scopeValue: activeOrganizationId } = useAppSelector((state) => state.user.activeScope);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isCreate, setIsCreate] = useState<boolean>(false);
	const [facilityClicked, setFacilityClicked] = useState<RenderingFacility>(
		{} as RenderingFacility
	);
	const [searchTerm, setSearchTerm] = useState('');
	const [facilitiesList, setFacilitiesList] = useState<RenderingFacility[]>(
		[] as RenderingFacility[]
	);

	const columns: GridColDef[] = [
		{ field: 'npi', headerName: 'NPI', width: 130 },
		{ field: 'name', headerName: 'Name', width: 350 },
		{ field: 'formattedAddress', headerName: 'Address', width: 400 },
	];

	useEffect(() => {
		let facilitiesToFilter = facilities;
		if (searchTerm.length > 0) {
			facilitiesToFilter = facilitiesToFilter.filter(
				(facility: RenderingFacility) =>
					JSON.stringify(facility).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
			);
		}
		setFacilitiesList(facilitiesToFilter);
	}, [facilities, searchTerm]);

	const onRowClick = (id: string): void => {
		const facility =
			facilities.find((fac: RenderingFacility) => id === fac.pk + fac.sk) ??
			({} as RenderingFacility);

		setFacilityClicked(facility);
		dispatch(fetchProviderFacilityRule({ panProviderId: facility.panProviderId })).then(() =>
			setIsOpen(true)
		);
	};

	const onDialogClose = (): void => {
		setFacilityClicked({} as RenderingFacility);
		dispatch(setFacilityRule({} as FacilityRule));
		setIsCreate(false);
		setIsOpen(false);
	};

	const onAddFacilityClick = (): void => {
		setFacilityClicked({} as RenderingFacility);
		setIsCreate(true);
		setIsOpen(true);
	};

	return (
		<Grid container>
			<Grid size={{ xs: 12, md: 2 }} sx={{ mb: 2 }}>
				<Button startIcon={<AddIcon />} onClick={() => onAddFacilityClick()}>
					Add Facility
				</Button>
			</Grid>
			<Grid size={{ xs: 12, md: 10 }} sx={{ mb: 2 }}>
				<TextField
					fullWidth
					id="search-facilities"
					label="Search"
					variant="outlined"
					size="small"
					slotProps={{
						input: {
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						},
					}}
					onInput={(e) => {
						setSearchTerm((e.target as HTMLTextAreaElement).value);
					}}
				/>
			</Grid>
			<Grid size={12}>
				<DataGrid
					autoHeight
					rows={facilitiesList}
					getRowId={(fac: RenderingFacility) => fac.pk + fac.sk}
					loading={
						isStatusPendingOrRefreshing([facilityRulesStatus]) && facilitiesList.length === 0
					}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 15]}
					onRowSelectionModelChange={(id) => {
						id.length > 0 ? onRowClick(id.toString()) : null;
					}}
					sx={{ borderColor: 'primary' }}
				/>
			</Grid>
			<Dialog fullWidth open={isOpen}>
				<EditFacility
					organizationId={activeOrganizationId}
					facility={facilityClicked}
					isCreate={isCreate}
					closeDialog={onDialogClose}
				></EditFacility>
			</Dialog>
		</Grid>
	);
};

export default FacilityRules;
