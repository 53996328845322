import React, { FC, ReactNode, useEffect, useState } from 'react';
import { getTotpSeedCode } from 'user-utilities';
import { Backdrop } from '@mui/material';
import { MfaSetup } from './MfaSetup';
import './mfaSetup.css';
import { fetchMFAPreference } from 'aws-amplify/auth';
import { MfaPreferenceStatus } from 'type-declarations';
import { CustomMessage } from '../CustomMessage/CustomMessage';

type Props = {
	children: ReactNode;
};

export const MfaSetupDialog: FC<Props> = ({ children }) => {
	const [seedCode, setSeedCode] = useState('NOCODE');
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [mfaPreference, setMfaPreference] = useState<MfaPreferenceStatus | null>(null);

	const getSeed = async (): Promise<string> => {
		setSeedCode(await getTotpSeedCode());
		return seedCode;
	};

	const handleUpdateMfaPreference = (): void => {
		setOpenDialog(false);
	};

	const isLoading = (): boolean => {
		return mfaPreference === null && !openDialog;
	};

	useEffect(() => {
		const fetchMfaPreference = async (): Promise<void> => {
			const mfaPreferenceResp =
				(await fetchMFAPreference()).preferred ?? MfaPreferenceStatus.NOT_SET;
			setOpenDialog(
				mfaPreferenceResp === MfaPreferenceStatus.EMAIL ||
					mfaPreferenceResp === MfaPreferenceStatus.NOT_SET
			);
			setMfaPreference(mfaPreferenceResp as MfaPreferenceStatus);
		};
		fetchMfaPreference();
		getSeed();
	}, []);

	return (
		<>
			{isLoading() ? (
				<CustomMessage title="Loading..." loading={isLoading()} />
			) : openDialog ? (
				<Backdrop invisible className="rhyme-background" open={openDialog}>
					<MfaSetup
						seedCode={seedCode}
						setOpen={setOpenDialog}
						onSuccessTotpUpdate={handleUpdateMfaPreference}
					/>
				</Backdrop>
			) : (
				children
			)}
		</>
	);
};
