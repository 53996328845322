import { PartnerEntity, ScopeKeyValuePair } from 'type-declarations';
import { PartnerState } from './slice';

//Selectors for PartnerState
//Selectors concepts explained here: https://redux.js.org/usage/deriving-data-selectors#calculating-derived-data-with-selectors

/**
 * Returns the PartnerEntity object associated to the given scope.
 */
// FIXME - constrain this to key as well
export const selectPartnerEntityGivenScope = (
	state: PartnerState,
	scope: ScopeKeyValuePair
): PartnerEntity => state.partners.find((p) => p.id === scope.scopeValue)!;
