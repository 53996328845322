import {
	Card,
	CardActions,
	CardContent,
	Typography,
	Alert,
	Button,
	Grid2 as Grid,
} from '@mui/material';
import { FC } from 'react';
import { useAppDispatch } from '@app/hooks/useReduxToolkit';
import AddOrEditUserDialog from './AddOrEditUserDialog';
import SendIcon from '@mui/icons-material/Send';
import { CrudDialogState, resendPartnerUserInvite } from 'state-services';
import { RhymeUser } from 'type-declarations';
import UserRoleChips from './UserRoleChips';
import DeleteUserDialog from './DeleteUserDialog';
import { ResetMfaDevice } from './ResetMfaDevice';

type Props = {
	userId: string;
	users: RhymeUser[];
	editDialogState: CrudDialogState;
	deleteDialogState: CrudDialogState;
	resetDialogState: CrudDialogState;
};

const UserDetails: FC<Props> = ({
	userId,
	users,
	editDialogState,
	deleteDialogState,
	resetDialogState,
}) => {
	const dispatch = useAppDispatch();
	let user = users.find((user: RhymeUser) => user.id === userId);
	if (user !== undefined && !user.userName) {
		user = { ...user };
	}

	const sendInvitation = (): void => {
		if (user?.userName !== undefined) {
			dispatch(resendPartnerUserInvite({ userName: user.userName }));
		}
	};

	return (
		<>
			{!user?.isConfirmed && (
				<Alert
					severity="info"
					sx={{ marginBottom: 1 }}
					action={
						<Button startIcon={<SendIcon />} onClick={() => sendInvitation()}>
							Send New Invite
						</Button>
					}
				>
					This user has not confirmed their details.
				</Alert>
			)}
			<Card variant="outlined">
				{user && (
					<CardContent>
						<Typography variant="h5" component="h5">
							{user.firstName} {user.lastName}
						</Typography>
						<Typography variant="body2" color="text.secondary">
							{user.userName}
						</Typography>
						<UserRoleChips user={user} />
					</CardContent>
				)}

				<CardActions sx={{ justifyContent: 'flex-end' }}>
					<Grid container direction="column" justifyContent="flex-end" spacing={2}>
						<Grid container justifyContent="flex-end" spacing={2}>
							<Grid>
								<AddOrEditUserDialog user={user!} dialogState={editDialogState} />
							</Grid>
							<Grid>
								<ResetMfaDevice user={user!} dialogState={resetDialogState} />
							</Grid>
						</Grid>

						<Grid container justifyContent="flex-end">
							<Grid>
								<DeleteUserDialog user={user!} dialogState={deleteDialogState} />
							</Grid>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		</>
	);
};

export default UserDetails;
