import {
	Box,
	IconButton,
	TextField,
	Typography,
	Button,
	Card,
	CardContent,
	Alert,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { verifyTotpCode } from 'user-utilities';
import { QRCodeCanvas } from 'qrcode.react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AuthAppToolTip } from 'rhyme-component-library';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from 'aws-amplify/auth';

type Props = {
	seedCode: string;
	setOpen: (value: boolean) => void;
	onSuccessTotpUpdate: () => void;
};

export const MfaSetup: FC<Props> = ({ seedCode, setOpen, onSuccessTotpUpdate }) => {
	const [code, setCode] = useState('');
	const [errorMessage, setErrorMessage] = useState('');

	const saveClickHandler = async (): Promise<void> => {
		setErrorMessage('');
		const result = await verifyTotpCode(code);
		if (result) {
			onSuccessTotpUpdate();
			setOpen(false);
		} else {
			setErrorMessage('Invalid Verification Code. Please try again.');
		}
	};

	const codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setCode(event.target.value);
	};

	const getQrCodeUrl = (secretCode: string): string => {
		const { user } = useAuthenticator();
		const userEmail = user.signInDetails?.loginId;
		return (
			'otpauth://totp/AWSCognito:' + userEmail + '?secret=' + secretCode + '&issuer=' + 'Cognito'
		);
	};

	const handleCopyClick = (): void => {
		navigator.clipboard.writeText(seedCode);
	};

	const shouldMfaSaveButtonDisable = (): boolean => {
		return code.trim().length !== 6;
	};

	return (
		<>
			<Card>
				<CardContent>
					<Box display="flex" justifyContent="center" alignItems="center">
						<Typography variant="h4" fontWeight="bold">
							Authenticator App Setup
						</Typography>
						<AuthAppToolTip />
					</Box>
					<Box
						justifyContent="center"
						alignContent="center"
						display="flex"
						sx={{ padding: '1rem' }}
					>
						<Typography variant="subtitle2">
							Scan the QR code or enter the key into your app. <br />
							Enter the resulting Verification Code ino the field below.
						</Typography>
					</Box>
					<Box sx={{ justifyContent: 'center', display: 'flex', paddingTop: '1rem' }}>
						<QRCodeCanvas size={250} value={getQrCodeUrl(seedCode)} />
					</Box>
					<Box flexDirection="column" gap={1} justifyContent="center" sx={{ padding: '1rem' }}>
						<Typography variant="caption">{seedCode}</Typography>
						<IconButton size="small" onClick={handleCopyClick} aria-label="copy">
							<ContentCopyIcon fontSize="inherit" />
						</IconButton>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						gap={2}
						width="100%"
						justifyContent="center"
						sx={{ padding: '2rem' }}
					>
						{errorMessage.length > 0 && <Alert severity="error">{errorMessage}</Alert>}
						<Typography variant="subtitle2">Verification Code*</Typography>
						<TextField label="Code" size="small" onChange={codeChangeHandler}></TextField>
						<Button disabled={shouldMfaSaveButtonDisable()} onClick={saveClickHandler}>
							Confirm
						</Button>
						<Button disabled={!shouldMfaSaveButtonDisable()} onClick={() => signOut()}>
							Back to Sign In
						</Button>
					</Box>
				</CardContent>
			</Card>
		</>
	);
};
