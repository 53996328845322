import { createSlice, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { getMrnCaseLookup, getRsoLink, getRsoRedirtectOrCases } from './thunks';
import { SnackbarManager } from 'rhyme-component-library';
import {
	EpicCase,
	EpicFhirState,
	EpicPatient,
	EpicAuthData,
	EpicPractitioner,
} from './utils/types';
import { SliceStatus } from 'type-declarations';
import { clearSessionStorage } from './utils/vendorAuthUtils';

const initialState: EpicFhirState = {
	status: SliceStatus.PENDING,
	rsoReqStatus: SliceStatus.IDLE,
	isEmpa: true, // State key used in app for isEmpa tracking
	epicPatient: null,
	epicPractitioner: null,
	epicAuthResponse: null,
	errorMessages: [],
	actionItems: {
		rsoRedirectUrl: null,
		cases: null,
	},
};

export const epicFhirSlice = createSlice({
	name: 'epicFhir',
	initialState,
	reducers: {
		setEpicAuthResponse: (state, action: PayloadAction<EpicAuthData>) => {
			state.epicAuthResponse = action.payload;
		},
		setEpiPatient: (state, action: PayloadAction<EpicPatient>) => {
			state.epicPatient = action.payload;
		},
		setEpicPractioner: (state, action: PayloadAction<EpicPractitioner>) => {
			state.epicPractitioner = action.payload;
		},
		setErrorMessages: (state, action: PayloadAction<string[]>) => {
			clearSessionStorage();
			state.errorMessages = [...state.errorMessages, ...action.payload];
			state.status = SliceStatus.REJECTED;
		},
		cleanupAndRedirect: (state) => {
			clearSessionStorage();
			if (state.actionItems.rsoRedirectUrl) window.location.href = state.actionItems.rsoRedirectUrl;
		},
	},
	extraReducers(builder) {
		builder.addCase(getRsoRedirtectOrCases.pending, (state) => {
			state.status = SliceStatus.PENDING;
		});
		builder.addCase(getMrnCaseLookup.fulfilled, (state, action: PayloadAction<EpicCase[]>) => {
			const cases = action.payload;
			if (cases.length > 1) cases.sort((a, b) => (a.dateOfService < b.dateOfService ? 1 : -1));
			state.actionItems.cases = cases;
			state.status = SliceStatus.SUCCEEDED;
		});
		builder.addCase(getRsoLink.pending, (state) => {
			state.rsoReqStatus = SliceStatus.PENDING;
		});
		builder.addCase(getRsoLink.fulfilled, (state, action: PayloadAction<string>) => {
			state.rsoReqStatus = SliceStatus.SUCCEEDED;
			state.actionItems.rsoRedirectUrl = action.payload;
		});
		builder.addCase(getRsoLink.rejected, (state) => {
			state.rsoReqStatus = SliceStatus.REJECTED;
			SnackbarManager.show({ message: 'RSO redirect failed.', type: 'error' });
		});
		// Catch-all for rejected thunks
		builder.addMatcher(isRejected, (state, action) => {
			state.status = SliceStatus.REJECTED;
			action.payload
				? state.errorMessages.push(action.payload as string)
				: state.errorMessages.push('An unknown error occurred');
		});
	},
});

export const epicFhirReducer = epicFhirSlice.reducer;
export const epicFhirActions = epicFhirSlice.actions;
export const {
	cleanupAndRedirect,
	setEpicAuthResponse,
	setEpiPatient,
	setEpicPractioner,
	setErrorMessages,
} = epicFhirActions;
