export enum ScopeKeyOptions {
	ORGANIZATION_ID = 'organizationId',
	PAYER_INSTITUTION_ID = 'payerInstitutionId',
	DELEGATED_BENEFIT_MANAGER_ID = 'delegatedBenefitManagerId',
}

export enum EntityType {
	PROVIDER_ORGANIZATION = 'ProviderOrganization',
	PAYER_INSTITUTION = 'PayerInstitution',
	DELEGATED_BENEFIT_MANAGER = 'DelegatedBenefitManager',
}

// NOTE: values must be power of 2 so that we can use these as flags
export enum EntityGroup {
	ORGANIZATION = 1,
	PAYER_INSTITUTION = 2,
	DELEGATED_BENEFIT_MANAGER = 4,
	NOT_ORGANIZATION = PAYER_INSTITUTION | DELEGATED_BENEFIT_MANAGER,
}

export type EntityOption = {
	id: string;
	name: string;
	entityType: EntityType;
};

export const entityToScopeKeyMapping: { [key in EntityType]: ScopeKeyOptions } = {
	[EntityType.PROVIDER_ORGANIZATION]: ScopeKeyOptions.ORGANIZATION_ID,
	[EntityType.PAYER_INSTITUTION]: ScopeKeyOptions.PAYER_INSTITUTION_ID,
	[EntityType.DELEGATED_BENEFIT_MANAGER]: ScopeKeyOptions.DELEGATED_BENEFIT_MANAGER_ID,
};

export const scopeKeyToEntityMapping: { [key in ScopeKeyOptions]: EntityType } = {
	[ScopeKeyOptions.ORGANIZATION_ID]: EntityType.PROVIDER_ORGANIZATION,
	[ScopeKeyOptions.PAYER_INSTITUTION_ID]: EntityType.PAYER_INSTITUTION,
	[ScopeKeyOptions.DELEGATED_BENEFIT_MANAGER_ID]: EntityType.DELEGATED_BENEFIT_MANAGER,
};
