import { useAppDispatch } from '@app/hooks/useReduxToolkit';
import { closePartnerUsersDialog, CrudDialogState, openPartnerUsersDialog } from 'state-services';
import { RhymeUser } from 'type-declarations';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { FC } from 'react';

type Props = {
	user: RhymeUser;
	dialogState: CrudDialogState;
};

export const ResetMfaDevice: FC<Props> = ({ user, dialogState }) => {
	const dispatch = useAppDispatch();
	const isOpen = dialogState.isDialogOpen;
	const userEmail = user.userName ?? '';
	const userName = user?.firstName + ' ' + user?.lastName;

	const handleReset = (): void => {
		dispatch(dialogState.confirmAction({ user }));
		closeDialog();
	};

	const openDialog = (): void => {
		dispatch(openPartnerUsersDialog(dialogState));
	};

	const closeDialog = (): void => {
		dispatch(closePartnerUsersDialog(dialogState));
	};

	return (
		<>
			<Button size="small" startIcon={<PhonelinkLockIcon />} onClick={openDialog}>
				Reset MFA App
			</Button>
			<Dialog open={isOpen}>
				<DialogTitle color="primary" fontWeight="bold" className="reset-title">
					Reset User&apos;s MFA App
				</DialogTitle>
				<DialogContent sx={{ mt: 0 }}>
					<Typography variant="subtitle1" fontWeight="bold">
						Are you sure you want to reset this user&apos;s MFA app?
					</Typography>
					<Stack direction="row" justifyContent="center" marginY={1} spacing={1}>
						<Chip label={userName} />
						<Chip label={userEmail} />
					</Stack>
					<Typography variant="subtitle1" fontWeight="bold">
						After confirming, the user must:
						<List>
							<ListItem disablePadding>
								<ListItemText primary="1. Log in." />
							</ListItem>
							<ListItem disablePadding>
								<ListItemText primary="2. Provide a Verification Code sent to their email address." />
							</ListItem>
							<ListItem disablePadding>
								<ListItemText primary="3. Set up a new MFA App." />
							</ListItem>
						</List>
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleReset}
						startIcon={<CheckCircleIcon />}
						disabled={dialogState.isSubmitting}
					>
						{dialogState.isSubmitting ? 'Resting...' : 'Confirm'}
					</Button>
					<Button
						onClick={closeDialog}
						startIcon={<CancelIcon />}
						disabled={dialogState.isSubmitting}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
