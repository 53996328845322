import {
	AccessScope,
	IdTokenPayload,
	ScopeKeyOptions,
	ScopeKeyValuePair,
	UserRole,
} from 'type-declarations';

export const getIdTokenPayloadFromIdTokenJwt = (idTokenJwt: string): IdTokenPayload => {
	if (!idTokenJwt) return {} as IdTokenPayload;
	try {
		const sections = idTokenJwt.split('.');
		const idTokenPayload = JSON.parse(atob(sections[1])) as IdTokenPayload;
		return idTokenPayload;
	} catch {
		return {} as IdTokenPayload;
	}
};

/**
 * Accepts a user's list of UserRoles and combines them into an array of AccessScopes. The returned
 * AccessScope array is used to control external-facing applications so that the user can switch between
 * scopes relevant to the application.
 */
export const getAccessScopesFromUserRoles = (userRoles: UserRole[]): AccessScope[] => {
	const result = userRoles.reduce((accessSopeList: AccessScope[], currentRole: UserRole) => {
		const scopeKey = Object.keys(currentRole.scope)[0];
		const scopeValue = currentRole.scope[scopeKey];
		const existingScope = accessSopeList.find(
			(e) => e.scopeKey === scopeKey && e.scopeValue === scopeValue
		);

		if (existingScope) {
			existingScope.roles.push(currentRole.name);
			const set = new Set([...existingScope.capabilities, ...currentRole.capabilities]);
			existingScope.capabilities = Array.from(set);
		} else {
			accessSopeList.push({
				scopeValue: scopeValue,
				roles: [currentRole.name],
				scopeKey: scopeKey,
				capabilities: currentRole.capabilities,
			} as AccessScope);
		}
		return accessSopeList;
	}, []);
	return result;
};

/**
 * If the current URL path contains information about what Partner Entity the user is viewing something as,
 * use that Partner Entity as the active ScopeKeyValuePair.
 * Currently only relevant for Provider Organizations in react-tachyon.
 */
export const getActiveScopeKeyValuePairFromPath = (
	windowLocation: Location
): ScopeKeyValuePair | null => {
	const pathParts = windowLocation.pathname?.split('/') ?? [];
	const indexOfOrganization = pathParts.indexOf('organization');
	if (indexOfOrganization > -1) {
		//pattern is organization/1234567890 -- so the orgId will immediately
		//follow the string "organization" in the split array
		const orgIdFromRoute = pathParts[indexOfOrganization + 1];
		if (orgIdFromRoute && orgIdFromRoute.length === 10) {
			return {
				scopeKey: ScopeKeyOptions.ORGANIZATION_ID,
				scopeValue: orgIdFromRoute,
			};
		}
	}
	return null;
};

/**
 * Finds the active access scope for an initial page load following these priorities:
 * 1). AccessScope from the current window path (only for orgs in react-tachyon right now).
 * 2). First Payer Institution scope.
 * 3). If none, first Provider Organization scope.
 * 4). If none, first Delegated Benefit Manager scope.
 */
export const getActiveAccessScope = (accessScopes: AccessScope[]): AccessScope => {
	try {
		const activeScopeKeyValuePairFromPath = getActiveScopeKeyValuePairFromPath(window.location);
		if (activeScopeKeyValuePairFromPath) {
			return accessScopes.find(
				(s) =>
					s.scopeKey === activeScopeKeyValuePairFromPath.scopeKey &&
					s.scopeValue === activeScopeKeyValuePairFromPath.scopeValue
			)!;
		}
	} catch {
		//left empty because this is just to enable testing
	}

	if (accessScopes.length <= 0) {
		return { scopeKey: '', scopeValue: '', capabilities: [], roles: [] };
	}

	const payerInstitution = accessScopes.find(
		(e) => e.scopeKey === ScopeKeyOptions.PAYER_INSTITUTION_ID
	);
	const providerOrg = accessScopes.find((s) => s.scopeKey === ScopeKeyOptions.ORGANIZATION_ID);

	// choose a payer first, if none then provider org, then fall back to random first access scope
	const activeAccessScope: AccessScope = payerInstitution
		? payerInstitution
		: providerOrg
			? providerOrg
			: accessScopes[0];

	return activeAccessScope;
};
