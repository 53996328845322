/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
	Authenticator,
	Button,
	Image,
	View,
	Text,
	useAuthenticator,
	useTheme,
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify/utils';
import './rhymeAuthenticator.scss';
import React from 'react';
import RhymeLockup from '../../assets/rhyme_lockup.svg';
import AmplifyAuthInterceptor from './AmplifyAuthInterceptor';
import { signIn, confirmSignIn, rememberDevice } from 'aws-amplify/auth';
import { Alert, AlertTitle, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { AuthAppToolTip } from './components/AuthAppToolTip';
import { MfaSetupDialog } from '../mfa/MfaSetupDialog';

//available keys
//https://github.com/aws-amplify/amplify-ui/blob/main/packages/ui/src/i18n/dictionaries/authenticator/en.ts
I18n.putVocabulariesForLanguage('en', {
	'Sign in': 'SIGN IN', // Button label
	Email: 'Email Address *', // Username label
	Password: 'Password *', // Password label
});

const formFields = {
	setupTotp: {
		confirmation_code: {
			label: 'Verification Code*', //input title
			placeholder: 'Code', //input place holder text
			confirm: 'Confirm', //confirm button text
			isRequired: false,
		},
	},
	confirmSignIn: {
		confirmation_code: {
			label: 'Verification Code*', //input title
			placeholder: 'Code', //input place holder text
			confirm: 'Confirm', //confirm button text
			isRequired: false,
		},
	},
};

type Props = {
	isRso?: boolean; //if true skip aws authenticator signin
	children: React.ReactNode;
};

function generateHeaderFooters(shouldRememberDevice, handleShouldRememberDevice) {
	return {
		SignIn: {
			Header() {
				const { tokens } = useTheme();
				return (
					<View textAlign="center" padding={tokens.space.large}>
						<Image alt="Rhyme Logo and Company Name" src={RhymeLockup} />
					</View>
				);
			},
			Footer() {
				const { toForgotPassword } = useAuthenticator();
				return (
					<View textAlign="center">
						<Button fontWeight="normal" onClick={toForgotPassword} size="small" variation="link">
							Forgot your password?
						</Button>
						<br />
						<Alert severity="info" sx={{ textAlign: 'left' }}>
							<AlertTitle sx={{ fontWeight: 'bold' }}>MFA App Enforcement Coming</AlertTitle>
							Starting Monday, February 3rd, Multi-Factor Authentication (MFA) App will be required.
							Please make sure to install an Authenticator app on your phone ahead of time so you’re
							ready to update your account.
						</Alert>
						<br />
						<p>
							Rhyme Portal: {process.env.REACT_APP_ENV} - {process.env.REACT_APP_ADO_BUILD_NUMBER}
						</p>
					</View>
				);
			},
		},
		ConfirmSignIn: {
			Header() {
				const { challengeName } = useAuthenticator();
				return (
					<View>
						{/* MFA authenticator app */}
						{challengeName == 'SOFTWARE_TOKEN_MFA' && (
							<>
								<Stack spacing={1}>
									<Typography variant="h4" fontWeight={'fontWeightBold'}>
										Verification Code
									</Typography>
									<Typography variant="subtitle1">
										Enter the Verification Code from your <strong>authenticator app</strong> in the
										field below.
									</Typography>
								</Stack>
							</>
						)}
						{/* MFA email */}
						{challengeName == 'SMS_MFA' && (
							<>
								<Typography variant="h4" fontWeight={'fontWeightBold'}>
									Verify Your Email Address
								</Typography>
								<Typography variant="subtitle1">
									We <strong>emailed you a code</strong> to verify your email address. Please enter
									the code below.
								</Typography>
							</>
						)}
					</View>
				);
			},
			Footer() {
				const handleContactSupport = () => {
					window.location.href = 'mailto:support@getrhyme.com';
				};
				const { challengeName } = useAuthenticator();
				return (
					<View>
						{challengeName == 'SOFTWARE_TOKEN_MFA' && (
							<>
								<FormControlLabel
									control={
										<Checkbox
											checked={!!shouldRememberDevice}
											onChange={handleShouldRememberDevice}
											color="primary"
											disableRipple
										/>
									}
									label={
										<span style={{ fontSize: '.8em' }}>
											Skip this step in the future if this is your private device.
										</span>
									}
								/>
								<Stack direction="row" justifyContent="center" sx={{ paddingTop: 2 }}>
									<Button size="small" variation="link" onClick={handleContactSupport}>
										<SupportAgentIcon />
										Contact Support
									</Button>
								</Stack>
							</>
						)}
					</View>
				);
			},
		},
		SetupTotp: {
			Header() {
				const { tokens } = useTheme();
				return (
					<>
						<Stack
							spacing={3}
							direction="row"
							sx={{ alignItems: 'center', justifyContent: 'center' }}
						>
							<Text
								fontWeight={tokens.fontWeights.bold}
								fontSize={tokens.fontSizes.xl}
								style={{ justifyContent: 'center', alignItems: 'center' }}
							>
								Authenticator App Setup
							</Text>
							<AuthAppToolTip />
						</Stack>

						<Stack spacing={1} sx={{ alignItems: 'center', justifyContent: 'left' }}>
							<Text fontWeight={tokens.fontWeights.normal} fontSize={tokens.fontSizes.medium}>
								Scan the QR code or enter the key into your app.
								<br />
								Enter the resulting Verification Code into the field below.
							</Text>
						</Stack>
					</>
				);
			},
		},
	};
}

function generateServiceOverrides(interceptor: AmplifyAuthInterceptor, shouldRememberRef) {
	return {
		handleSignIn(input) {
			interceptor.Activate();
			return signIn(input).then(async (output) => {
				//prior to signIn removing any expired rso token saved in local storage
				const token = localStorage.getItem('idToken');
				if (token) {
					localStorage.removeItem('idToken');
				}
				if (output.isSignedIn) {
					interceptor.Deactivate();
				} else {
					if (output.nextStep.signInStep == 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION') {
						// 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
						// If multiple MFAs are enabled, this shows up after login.
						// Theres only email and software token, so we prefer software token if it is set up
						const confirmOutput = await confirmSignIn({
							challengeResponse: 'TOTP',
						});
						return confirmOutput;
					}
				}
				return output;
			});
		},
		async handleConfirmSignIn(input) {
			return confirmSignIn(input).then(async (output) => {
				if (shouldRememberRef.current) {
					await rememberDevice();
				}
				interceptor.Deactivate();
				return output;
			});
		},
	};
}

//Authenticator docs
//https://ui.docs.amplify.aws/react/connected-components/authenticator/customization

export function RhymeAuthenticator(props: Props) {
	const { children, isRso = false } = props;
	const interceptor = new AmplifyAuthInterceptor();
	window.addEventListener('beforeunload', () => {
		interceptor.Deactivate();
	});

	const shouldRememberRef = React.useRef(false);
	const [shouldRememberDevice, setShouldRememberDevice] = React.useState(false);
	const handleShouldRememberDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
		setShouldRememberDevice(event.target.checked);
		shouldRememberRef.current = event.target.checked;
	};

	return (
		<>
			{isRso ? (
				children
			) : (
				<Authenticator
					hideSignUp
					formFields={formFields}
					components={generateHeaderFooters(shouldRememberDevice, handleShouldRememberDevice)}
					loginMechanisms={['email']}
					services={generateServiceOverrides(interceptor, shouldRememberRef)}
				>
					<MfaSetupDialog>{children}</MfaSetupDialog>
				</Authenticator>
			)}
		</>
	);
}
