import { ListItemButton, ListItemText, Menu, MenuItem, MenuList, ListItem } from '@mui/material';
import React, { useEffect, FC } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import {
	fetchProviderFacilities,
	restFacilities,
	fetchProviderDefaultContact,
	fetchPartnerUsers,
	resetDefaultContact,
	resetPartnerUsers,
	UserState,
	PartnerState,
	updateActiveScope,
} from 'state-services';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { EntityTypeActions } from 'user-utilities';
import { EntityGroup, EntityOption, getScopeKeyForEntity, UserRoleTypes } from 'type-declarations';
import { useActivePartnerEntity } from '@app/hooks/useActivePartnerEntity';

const SelectOrganizationMenu: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { partners } = useAppSelector<PartnerState>((state) => state.partner);
	const { activeScope } = useAppSelector<UserState>((state) => state.user);
	const activePartnerEntity = useActivePartnerEntity();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (): void => {
		setAnchorEl(null);
	};

	const handleOrgChange = (entity: EntityOption): void => {
		const scopeKeyFromEntity = getScopeKeyForEntity(entity.entityType);
		dispatch(updateActiveScope({ scopeKey: scopeKeyFromEntity, scopeValue: entity.id }));
		setAnchorEl(null);
		navigate('/');
	};

	const getEntityList = (): EntityOption[] => {
		const entityList: EntityOption[] = [];
		if (partners.length > 0) {
			partners.map((e) => {
				entityList.push({
					id: e.id,
					name: e.name,
					entityType: e.partnerType,
				});
			});
		}

		return entityList;
	};

	/**
	 * only call admin API's if user is
	 * providerAdmin or payerAdmin
	 */
	useEffect(() => {
		if (
			activeScope?.roles.includes(UserRoleTypes.ProviderAdmin) ||
			activeScope?.roles.includes(UserRoleTypes.PayerAdmin) ||
			activeScope?.roles.includes(UserRoleTypes.DbmAdmin)
		) {
			EntityTypeActions.isInEntityGroup(
				activePartnerEntity.partnerType,
				EntityGroup.NOT_ORGANIZATION
			)
				? dispatch(fetchPartnerUsers())
				: (dispatch(fetchPartnerUsers()),
					dispatch(fetchProviderDefaultContact()),
					dispatch(fetchProviderFacilities()));
		} else {
			EntityTypeActions.isInEntityGroup(
				activePartnerEntity.partnerType,
				EntityGroup.NOT_ORGANIZATION
			)
				? dispatch(resetPartnerUsers())
				: (dispatch(resetDefaultContact()),
					dispatch(restFacilities()),
					dispatch(resetPartnerUsers()));
		}
	}, [activePartnerEntity]);

	return (
		<>
			<ListItem>
				<ListItemButton
					id="basic-button"
					aria-controls={open ? 'organization-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					<ListItemText>Switch Organization</ListItemText>
					<ChevronRightIcon />
				</ListItemButton>
			</ListItem>
			<Menu
				id="organization-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
			>
				<MenuList dense>
					{getEntityList().length > 0 &&
						getEntityList().map((e) => {
							return (
								<MenuItem
									dense
									key={e.id}
									selected={e.id === activePartnerEntity.id}
									onClick={(): void => handleOrgChange(e)}
								>
									{e.name}
								</MenuItem>
							);
						})}
				</MenuList>
			</Menu>
		</>
	);
};

export default SelectOrganizationMenu;
