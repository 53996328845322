import { Alert, AlertTitle, Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import { FC, useEffect } from 'react';
import LighthouseSkeletonLoaderAndErrorAlert from './LighthouseSkeletonLoaderAndErrorAlert';
import { EntityType, PartnerEntity } from 'type-declarations';
import LighthouseApplication from './LighthouseApplication';
import { closeLighthouse, getLighthouseEmbedUrl, PartnerState, UserState } from 'state-services';
import './styles/lighthouse.scss';
import { UserCapabilities } from '@app/shared/constants';
import { CustomMessage } from 'rhyme-component-library';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useActivePartnerEntity } from '@app/hooks/useActivePartnerEntity';

const Lighthouse: FC = () => {
	const dispatch = useAppDispatch();
	const { accessScopes } = useAppSelector<UserState>((state) => state.user);
	const partnerState = useAppSelector<PartnerState>((state) => state.partner);
	const activePartnerEntity = useActivePartnerEntity();
	const { partners } = partnerState;

	const isLoadingLighthouseDependencies = (): boolean => {
		return !activePartnerEntity;
	};

	//Handle tab closed -- update Sentry tracking.
	window.addEventListener('beforeunload', () => {
		dispatch(closeLighthouse());
	});
	//Handle navigating away from Lighthouse -- update Sentry tracking.
	useEffect(
		() => (): void => {
			dispatch(closeLighthouse());
		},
		[]
	);

	const filterOrgsWithDashboardAccess = (
		providerOrganizations: Array<PartnerEntity>
	): Array<PartnerEntity> => {
		//FIXME - fix partnerType/scopeKey constraint
		return providerOrganizations.filter((p) =>
			accessScopes.some(
				(a) =>
					// a.scopeKey === p.partnerType &&
					a.scopeValue === p.id && a.capabilities.includes(UserCapabilities.GetLighthouseDashboard)
			)
		);
	};

	useEffect(() => {
		if (
			activePartnerEntity &&
			activePartnerEntity.partnerType !== EntityType.DELEGATED_BENEFIT_MANAGER
		) {
			let selectedPartnerEntities: Array<PartnerEntity> = [];
			const isProviderOrganization =
				activePartnerEntity.partnerType === EntityType.PROVIDER_ORGANIZATION;
			if (isProviderOrganization) {
				const providerOrganizations = partners.filter(
					(p) => p.partnerType === EntityType.PROVIDER_ORGANIZATION && p.isLighthouseEnabled
				);
				selectedPartnerEntities = filterOrgsWithDashboardAccess(providerOrganizations);
			} else {
				selectedPartnerEntities = [activePartnerEntity];
			}
			if (selectedPartnerEntities.length > 0) {
				dispatch(getLighthouseEmbedUrl({ activePartnerEntity, selectedPartnerEntities }));
			}
		}
	}, [activePartnerEntity]);

	return (
		<Box id="lighthouse-dashboard" sx={{ p: { xs: 2, sm: 3 } }}>
			{!activePartnerEntity.isLighthouseEnabled ? (
				<CustomMessage
					title="Coming soon..."
					subtitle="Please reach out to your Rhyme Relationship Manager to enable access for your organization."
				/>
			) : activePartnerEntity.partnerType === EntityType.DELEGATED_BENEFIT_MANAGER ? (
				<Alert severity="info" icon={<ConstructionIcon fontSize="inherit" />}>
					<AlertTitle>Under Maintenance</AlertTitle>
					Lighthouse is currently undergoing maintenance.
				</Alert>
			) : isLoadingLighthouseDependencies() ? (
				<LighthouseSkeletonLoaderAndErrorAlert />
			) : (
				<LighthouseApplication partners={filterOrgsWithDashboardAccess(partners)} />
			)}
		</Box>
	);
};

export default Lighthouse;
