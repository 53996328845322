import { FC, useState } from 'react';
import { PartnerEntity } from 'type-declarations';
import { Grid2 as Grid, Skeleton } from '@mui/material';
import LighthouseToolbar from './LighthouseToolbar';
import './styles/lighthouse.scss';
import LighthouseEmbedding from './LighthouseEmbedding';
import { useAppSelector } from '@app/hooks/useReduxToolkit';
import LighthouseSkeletonLoaderAndErrorAlert from './LighthouseSkeletonLoaderAndErrorAlert';

interface Props {
	partners: PartnerEntity[];
}

const LighthouseApplication: FC<Props> = ({ partners }) => {
	const { embedUrlsDetails } = useAppSelector((state) => state.lighthouse);
	const [initialFrameLoaded, setInitialFrameLoaded] = useState(false);

	const onInitialFrameLoaded = (): void => {
		setInitialFrameLoaded(true);
	};

	return (
		<>
			{embedUrlsDetails.length <= 0 ? (
				<LighthouseSkeletonLoaderAndErrorAlert />
			) : (
				<>
					<LighthouseToolbar
						partners={partners}
						disabled={!initialFrameLoaded}
						embedUrlsDetails={embedUrlsDetails}
					/>
					<Grid container>
						<Grid size={{ xs: 12 }}>
							{!initialFrameLoaded && (
								<Skeleton variant="rounded" height={700} sx={{ marginTop: 2 }} />
							)}
							{embedUrlsDetails &&
								embedUrlsDetails.map((embedUrlsDetail, index) => {
									return (
										<LighthouseEmbedding
											key={index}
											embedDashboardUrlResponse={embedUrlsDetail}
											onInitialFrameLoaded={onInitialFrameLoaded}
											initialFrameLoaded={initialFrameLoaded}
										/>
									);
								})}
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default LighthouseApplication;
