import { ScopeKeyValuePair } from 'services/user/models';
import {
	entityToScopeKeyMapping,
	EntityType,
	ScopeKeyOptions,
	scopeKeyToEntityMapping,
} from './user-types';

export const toScopeAsUrlParameter = (scope: ScopeKeyValuePair): string =>
	`${scope.scopeKey}=${scope.scopeValue}`;

export function getScopeKeyForEntity(entity: EntityType): ScopeKeyOptions {
	return entityToScopeKeyMapping[entity];
}

export function getEntityTypeForScopeKey(scopeKey: ScopeKeyOptions): EntityType {
	return scopeKeyToEntityMapping[scopeKey];
}
