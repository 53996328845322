import {
	CaseReasonIds,
	CaseStatus,
	ColorClass,
	DisplayServiceSource,
	SubmissionProcessingStatus,
	SubmissionState,
} from '../../utilities/case/enums';
import { FileData, RetrievableFileData, ValidationError } from 'type-declarations';

export interface CaseModel {
	addressCorrections: Array<AddressCorrection>;
	benefitManager: string;
	benefitManagerIds: Array<string>;
	benefitManagerNote: string;
	caseId: string;
	clinicalArtifacts: Array<string>;
	clinicalDocuments: Array<RetrievableFileData>;
	clinicalNotes: Array<RetrievableFileData>;
	createdDate: string;
	downloadableDocuments: Array<RetrievableFileData>;
	files: Array<FileData>;
	hashKey: string;
	metadata: object;
	modifiedDate: string;
	neopCommStatus: string;
	organizationId: string;
	partnerCaseId: string;
	payerArtifacts: Array<RetrievableFileData>;
	payerQuestions: Array<string>;
	providerSelections: Array<string>;
	reasonDescription: string;
	reasonDescriptionId: string;
	reasonId: CaseReasonIds;
	referral: Referral;
	statusId: CaseStatus;
	submissions: Array<Submission>;
	transactionType: string;
	validations: Array<ValidationError>;
	version: number;
}

export interface Contact {
	name: string;
	email: string;
	phone: string;
	contactType: string;
	fax: string;
}

export interface Code {
	code: string;
	units: number;
	reasonId: string;
	statusId: string;
	benefitManagerNote: string;
	authId: string;
	startDate: string;
	endDate: string;
	additionallyApprovedCodes: unknown; //TODO: Setup TS interface
	reasonDescriptionId: string;
	reasonDescription: string;
}

export interface Submission {
	submissionId: string;
	routeId: string;
	services: Array<string>;
	submissionTimeUtc: string;
	payerSubmissionIdExternal: string;
	payerSubmissionIdInternal: string;
	submissionStatus: SubmissionStatus;
	payerUpdatedRenderingFacility: Facility;
	payerUpdatedOrderingProvider: Provider;
	processingStatus: SubmissionProcessingStatus;
	submissionFiles: Array<string>;
}

export interface Referral {
	panPayerId?: string;
	serviceLevel?: ServiceLevel;
	subscriber: Subscriber;
	patient: Patient;
	orderingProvider?: Provider;
	renderingProvider?: Provider;
	renderingFacility?: Facility;
	services: Array<Service>;
	unsubmittedServices: Array<Service>;
	diagnoses?: Array<Diagnosis>;
	transactionType?: TransactionType | string;
	contacts?: Array<Contact>;
	// contact added since it comes in on the draft and it needs removed
	// to is causing validation errors. BACKEND JUNK
	contact?: Contact;
}

export interface Subscriber {
	subscriberId?: string;
	lastName?: string;
	firstName?: string;
	dob?: string; // ISO String plz
}

export interface Patient {
	firstName?: string;
	lastName?: string;
	dob?: string; // ISO String plz
	state?: string;
	patientId?: string;
	gender?: string;
	relationship?: string;
	height?: string;
	weight?: string;
}

export interface ServiceLevel {
	serviceTypeCode?: string;
	placeOfService?: string;
	levelOfService?: string;
	requestType?: string;
}

export interface Provider extends Facility {
	npi?: string;
	taxId?: string;
	firstName?: string;
	lastName?: string;
	address1?: string;
	city?: string;
	state?: string;
	zip?: string;
	phone?: string;
	fax?: string;
	email?: string;
	faxAttentionTo?: string;
	papi?: string;
	taxonomyCode?: string;
	networkStatus?: string;
}

export interface NpiLookupProvider extends Provider {
	providerFirstName?: string;
	providerLastName?: string;
	providerName?: string;
	addressLine1?: string;
}

export interface Facility {
	facilityName?: string;
	name?: string;
	npi?: string;
	taxId?: string;
	address1?: string;
	city?: string;
	state?: string;
	zip?: string;
	phone?: string;
	fax?: string;
	email?: string;
	faxAttentionTo?: string;
	papi?: string;
	taxonomyCode?: string;
	networkStatus?: string;
}

export interface Service {
	code: string;
	quantity?: number;
	dateOfService?: string;
	endDateOfService?: string;
	unitOfMeasure?: string;
	frequency?: string;
	frequencyType?: string;
	modifiers?: Array<string>;
	admissionTypeCode?: string;
	admissionSourceCode?: string;
	isPrimary?: boolean;
}

export interface Diagnosis {
	code: string;
	isPrimary: boolean;
}

export interface WaitingState {
	statusId: CaseStatus;
	reasonId: CaseReasonIds;
	benefitManagerNote: string;
}

export interface DisplayService {
	startDate?: string; // ISO Date string plz
	endDate?: string; //ISO Date string plz
	code: string;
	authId: string;
	refId: string;
	additionallyApprovedCodes: Array<string>;
}

export interface DisplayServiceInfo {
	service: DisplayService;
	statusId: string;
	reasonDescription: string;
	benefitManagerNote: string;
	source?: DisplayServiceSource;
}

export interface SubmissionStatus {
	// caseStatus: CaseStatus;
	status: CaseStatus;
	state: SubmissionState;
	waitingState?: WaitingState;
	decisionData?: DecisionData;
	reopenedData?: ReopenReason;
}

export interface ReopenReason {
	code: string;
	note: string;
}

export interface DecisionDataCode {
	code: string;
	units?: number;
	authId?: string;
	statusId?: CaseStatus;
	reasonId?: CaseReasonIds;
	reasonDescription?: string;
	reasonDescriptionId?: string;
	startDate?: string; // ISO Date String Plz
	endDate?: string; //  ISO Date String Plz
	additionallyApprovedCodes: Array<string>;
	benefitManagerNote?: string;
}
export interface WaitingState {
	statusId: CaseStatus;
	reasonId: CaseReasonIds;
	benefitManagerNote: string;
}

export interface DecisionData {
	statusId: CaseStatus;
	reasonId: CaseReasonIds;
	benefitManagerNote?: string;
	codes: Array<DecisionDataCode>;
}

export enum TransactionType {
	PRIOR_AUTHORIZATION = 'PRIOR_AUTHORIZATION',
	INPATIENT_NOTICE_OF_ADMISSION = 'INPATIENT_NOTICE_OF_ADMISSION',
}

export interface Metadata {
	[key: string]: string;
}

export interface EmailContact {
	name?: string;
	phone?: string;
	email?: string;
	fax?: string;
	// String enum
	contactType?: string;
}

export interface ProviderSelection {
	npi: string;
	taxId?: string;
	facilityName?: string;
	firstName?: string;
	lastName?: string;
	address1: string;
	addr1: string;
	addr2?: string;
	city: string;
	state: string;
	zip: string;
	papi?: string;
	networkStatus?: string;
	name?: string;
}

export enum AddressCorrectionType {
	OrderingProvider = 'ORDERING_PROVIDER',
	RenderingFacility = 'RENDERING_FACILITY',
	RenderingProvider = 'RENDERING_PROVIDER',
}

export interface AddressCorrection {
	addressType: AddressCorrectionType;
	providerSelections: Array<ProviderSelection>;
}

export interface PayerQuestion {
	id: string;
	text: string;
	options: Array<string>;
}

export enum DocumentTypes {
	ClinicalDocument = 'clinicalDocument',
	PayerArtifact = 'payerArtifact',
	ClinicalNote = 'clinicalNote',
}

export interface ProviderSelectionResponse {
	providerSelections: ProviderSelection[];
	total: number;
}

export enum SubmissionCorrectionsComponentNames {
	AddressChange = 'AddressChange',
	DateOfServiceChange = 'DateOfServiceChange',
	PayerQuestionsChange = 'PayerQuestionsChange',
	PaginatedAddressChange = 'PaginatedAddressChange',
	ModifierCorrectionChange = 'ModifierCorrectionChange',
}

export interface AddressCorrectionData {
	addressCorrectionType: AddressCorrectionType;
	providerSelections: Array<ProviderSelection>;
	dataSource: AddressDataSource;
}

export enum AddressDataSource {
	UMIntegration = 'UMIntegration',
	CaseProperties = 'CaseProperties',
	DataGathering = 'DataGathering',
}

export interface SubmissionToCorrect {
	component: SubmissionCorrectionsComponentNames;
	submissionId?: string | null;
	addressCorrectionData?: AddressCorrectionData;
	payerQuestion?: PayerQuestion;
}

export interface FetchAddressOptionsParams {
	existingAddress: AddressChangeable;
	caseBenefitManager: string;
	isFacilitySelection: boolean;
	organizationId: string;
	submissionToCorrect: SubmissionToCorrect;
	searchedTin: string;
}

export class AddressChangeable {
	npi!: string;
	taxId?: string;
	firstName?: string;
	lastName?: string;
	facilityName?: string;
	address1!: string;
	city!: string;
	state!: string;
	zip!: string;
	isFacility = false;
	papi?: string;
	index: number | undefined;
	networkStatus?: string;
	constructor(source: ProviderSelection | Provider | Facility, index?: number | undefined) {
		const sourceAsProviderSelection: ProviderSelection = source as ProviderSelection;
		const sourceAsProvider: Provider = source as Provider;
		const sourceAsFacility: Facility = source as Facility;
		this.npi = source.npi!;
		this.firstName = sourceAsProvider.firstName || sourceAsProviderSelection.firstName;
		this.lastName = sourceAsProvider.lastName || sourceAsProviderSelection.lastName;
		this.facilityName = sourceAsFacility.name || sourceAsProviderSelection.facilityName;
		this.address1 =
			sourceAsProviderSelection.addr1! || sourceAsProvider.address1! || sourceAsFacility.address1!;
		this.city = source.city!;
		this.state = source.state!;
		this.zip = source.zip!;
		this.isFacility = !!this.facilityName;
		this.taxId = source.taxId;
		this.papi = sourceAsProviderSelection.papi || sourceAsProvider.papi;
		this.index = index;
		this.networkStatus = source.networkStatus;
	}

	get displayName(): string {
		return this.facilityName || `${this.firstName} ${this.lastName}`;
	}
	get displayAddress(): string {
		return [this.address1, this.city, this.state, this.zip].join(', ');
	}
}

export interface ReopenReason {
	code: string;
	note: string;
}

export interface DecisionDataCode {
	code: string;
	units?: number;
	authId?: string;
	statusId?: CaseStatus;
	reasonId?: CaseReasonIds;
	reasonDescription?: string;
	reasonDescriptionId?: string;
	startDate?: string; // ISO Date String Plz
	endDate?: string; //  ISO Date String Plz
	additionallyApprovedCodes: Array<string>;
	benefitManagerNote?: string;
}

export interface CaseStatusDisplayMapping {
	statusId: CaseStatus;
	displayStatus: string;
	colorClass: ColorClass;
}

export interface CanWithdrawResponse {
	canWithdraw: boolean;
	reason: string;
	withdrawRequiresUserReason: boolean;
	withdrawReasons: withdrawReason;
}

export interface ProviderToCorrect {
	addressType: AddressCorrectionType;
	providerOptions: Array<ProviderSelection>;
	preexistingProvider: Provider;
	name: string;
}

export interface ProviderContext {
	activeScopeId: string;
	caseDetails: CaseModel;
	submissionId: string;
	paginationRequired: boolean;
	addressType: AddressCorrectionType | null;
	expressProviderSelection: boolean;
}

export interface withdrawReason {
	id: string;
	display: string;
}
