import { Button, Grid2 as Grid } from '@mui/material';
import { FC } from 'react';
import LighthouseTabs from './LighthouseTabs';
import LighthouseOrganizationSelect from './LighthouseOrganizationsSelect';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import { EmbedDashboardUrlResponse, EntityType, PartnerEntity } from 'type-declarations';
import { useAppDispatch, useAppSelector } from '@app/hooks/useReduxToolkit';
import { printLighthouseDashboard, resetLighthouseDashboard } from 'state-services';
import { useActivePartnerEntity } from '@app/hooks/useActivePartnerEntity';

interface Props {
	embedUrlsDetails: EmbedDashboardUrlResponse[];
	partners: PartnerEntity[];
	disabled: boolean;
}

const LighthouseToolbar: FC<Props> = ({ embedUrlsDetails, partners, disabled }) => {
	const dispatch = useAppDispatch();
	const activePartnerEntity = useActivePartnerEntity();
	const { selectedDashboardId } = useAppSelector((state) => state.lighthouse);

	const resetDashboard = (): void => {
		dispatch(resetLighthouseDashboard(selectedDashboardId));
	};

	const printDashboard = (): void => {
		dispatch(printLighthouseDashboard(selectedDashboardId));
	};
	const isProvider = (): boolean =>
		activePartnerEntity.partnerType === EntityType.PROVIDER_ORGANIZATION;
	return (
		<Grid container flexDirection="row-reverse">
			<Grid
				size={{ xs: 12, lg: isProvider() ? 12 : 5 }}
				sx={{ display: 'inline-flex', justifyContent: { md: 'flex-end' }, mb: 1, padding: 1 }}
			>
				<Button
					size="small"
					startIcon={<RefreshIcon />}
					disabled={disabled}
					onClick={resetDashboard}
					sx={{ marginLeft: 1, marginRight: 1 }}
				>
					Reset Dashboard
				</Button>
				<Button size="small" startIcon={<PrintIcon />} disabled={disabled} onClick={printDashboard}>
					Print Dashboard
				</Button>
			</Grid>
			{isProvider() && (
				<Grid size={{ xs: 12, md: 5 }}>
					<LighthouseOrganizationSelect partners={partners} disabled={disabled} />
				</Grid>
			)}
			<Grid size={{ xs: 12, lg: 7 }}>
				<LighthouseTabs embedUrlsDetails={embedUrlsDetails} disabled={disabled} />
			</Grid>
		</Grid>
	);
};

export default LighthouseToolbar;
