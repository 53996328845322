import { Buffer } from 'buffer';
import { getSessionStorage } from 'state-services';
import {
	EpicPractitioner,
	PanRequestParams,
	EpicAuthData,
	MrnRequestParams,
	TokenPayload,
	TokenRequestData,
	EpicPatient,
	EpicFhirState,
} from './types';

export const setupAccessTokenParams = (
	signedJwt: string,
	tokenRequestData: TokenRequestData
): URLSearchParams => {
	const authExchangeParams = new URLSearchParams();

	authExchangeParams.append('code', tokenRequestData.code);
	authExchangeParams.append('redirect_uri', tokenRequestData.redirectUrl ?? '');
	authExchangeParams.append('grant_type', 'authorization_code');
	authExchangeParams.append(
		'client_assertion_type',
		'urn:ietf:params:oauth:client-assertion-type:jwt-bearer'
	);
	authExchangeParams.append('client_assertion', signedJwt);

	return authExchangeParams;
};

export const tokenPayload = (idToken: string): TokenPayload => {
	// the payload portion of the auth response's id_token value
	const base64Payload = idToken.split('.')[1];
	const payload = Buffer.from(base64Payload, 'base64');
	return JSON.parse(payload.toString());
};

/**
 * @description Returns the params object used for the PSO link POST request.
 * @param {RootState} state FHIR state object
 * @param {EpicAuthData} epicAuthResData Patient data from Epic API response
 * @param {EpicPractitioner} practionerResData Practioner data from Epic API response
 * @param {string} selectedCaseId Optional UID attached to case from the /case-list route
 * Used for finding the case from the Redux state & setting either is caseID or partnerCaseId
 * for used in the request params object.
 * @returns {PanRequestParams} Request params object used in the RSO POST request
 */
export const getPanRsoRequestParams = (
	epicFhir: EpicFhirState,
	epicAuthResData: EpicAuthData,
	practionerResData: EpicPractitioner,
	selectedCaseId?: string
): PanRequestParams => {
	let selectedCase;
	if (epicFhir.actionItems.cases) {
		selectedCase = epicFhir.actionItems.cases.find((c) => selectedCaseId === c._id);
	}
	const caseId =
		epicAuthResData.X12TRN02 && !selectedCase
			? epicAuthResData.X12TRN02
			: selectedCase && selectedCase.caseId === ''
				? selectedCase.partnerCaseId
				: selectedCase?.caseId || '';
	const practitionerId = practionerResData.id;
	const practitionerIdentifiers = practionerResData.identifier;
	const name = practionerResData.name;
	const { baseUrl: IssValue } = getSessionStorage();

	return {
		caseId,
		practitionerId,
		practitionerIdentifiers,
		name,
		IssValue,
	};
};

/**
 * @description Returns the params object used for the MRN POST request.
 * @param {RootState} state FHIR state object
 * @param  {EpicPatient} epicPatient Patient case data returned from Epic API
 * @returns {MrnRequestParams} POST request params object
 */
export const getMrnCaseLookupRequestParams = (
	epicFhir: EpicFhirState,
	epicPatient: EpicPatient
): MrnRequestParams => {
	const { baseUrl } = getSessionStorage();
	return {
		IssValue: baseUrl,
		patientMrns: epicPatient.identifier,
		patientName: getPatientName(epicPatient),
	};
};

/**
 * @description Checks case data for patient name & attempts to return a name from various known locations.
 * @param {EpicPatient} patient Case data returned by the Epic API
 * @returns {string} Patient's full name
 */
export const getPatientName = (patient: EpicPatient): string => {
	const officialName = patient.name.find((o) => o.use?.toLowerCase() === 'official');
	const usualName = patient.name.find((u) => u.use?.toLowerCase() === 'usual');
	return officialName?.text ?? usualName?.text ?? patient?.name[0].text ?? '';
};
