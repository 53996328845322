/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RHYME_API_SERVICES, PartnerEntity } from 'type-declarations';
import { get } from 'aws-amplify/api';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

export const fetchPartners = createAsyncThunk(
	'partners/fetchPartners',
	async ({ partnerIds }: { partnerIds: string[] }) => {
		try {
			const responses = await Promise.all(
				partnerIds.map(async (partnerId) => {
					const { body } = await get({ apiName: rhymeApiUrl, path: `partner-entity/${partnerId}` })
						.response;
					return (await body.json()) as PartnerEntity;
				})
			).catch((err) => {
				console.warn(err);
				return [] as PartnerEntity[];
			});

			return responses;
		} catch (error) {
			console.warn(error);
		}
	}
);
