import { useActivePartnerEntity } from '@app/hooks/useActivePartnerEntity';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const App: FC = () => {
	const navigate = useNavigate();
	const activePartnerEntity = useActivePartnerEntity();

	//on app load redirect to lighthouse
	useEffect(() => {
		activePartnerEntity.isLighthouseEnabled ? navigate('/lighthouse') : navigate('/admin/users');
	}, []);

	return <></>;
};

export default App;
