import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbToolbar from '@app/shared/components/BreadcrumbToolbar';
import { payerAdminTabOptions, providerAdminTabOptions } from './constants';
import { setDocumentTitle, resetPageFocus } from 'acessibility-helpers';
import { EntityType } from 'type-declarations';
import { useActivePartnerEntity } from '@app/hooks/useActivePartnerEntity';

const Panel = (props: any): JSX.Element =>
	props.value === props.index ? (
		<Container maxWidth="xl" sx={{ pt: 1 }}>
			<Typography color="primary" variant="h4" marginBottom={2}>
				{props.title}
			</Typography>
			<div>{props.children}</div>
		</Container>
	) : (
		<></>
	);

const Admin: FC = () => {
	const [value, setValue] = useState(0);
	const { partnerType: activePartnerType } = useActivePartnerEntity();

	const tabOptions =
		activePartnerType === EntityType.PROVIDER_ORGANIZATION
			? providerAdminTabOptions
			: payerAdminTabOptions;

	const handleChange = (_e: React.SyntheticEvent, newValue: number): void => {
		setValue(newValue);
	};

	useEffect(() => {
		const path = window.location.pathname;

		setDocumentTitle('Rhyme: Portal Admin');
		resetPageFocus();

		switch (path) {
			case '/admin/users':
				setValue(0);
				break;
			case '/admin/default-contact':
				setValue(1);
				break;
			case '/admin/facility-rules':
				setValue(2);
				break;
			default:
				setValue(0);
				break;
		}
	}, []);

	return (
		<>
			<BreadcrumbToolbar />
			<Box
				height="calc(100% - 64px)"
				sx={{
					flexGrow: 1,
					display: 'flex',
					width: '100%',
				}}
			>
				<Tabs
					orientation="vertical"
					value={value}
					onChange={handleChange}
					variant="scrollable"
					aria-label="Admin Navigation"
					sx={{
						borderRight: 1,
						borderColor: 'divider',
						display: 'flex',
						width: '15%',
						height: '100%',
					}}
				>
					{tabOptions.map((item, index) => {
						const { text, path } = item;
						return (
							<Tab
								label={text}
								iconPosition="start"
								component={Link}
								to={path}
								key={index}
								sx={{
									justifyContent: 'start',
									whiteSpace: 'nowrap',
									textTransform: 'none',
									fontSize: '16px',
								}}
							/>
						);
					})}
				</Tabs>
				{tabOptions.map((item, index) => {
					return (
						<Panel key={index} value={value} index={index} title={item.text}>
							{item.component}
						</Panel>
					);
				})}
			</Box>
		</>
	);
};

export default Admin;
