import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, del, get, patch, post } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';
import {
	RhymeUser,
	RHYME_API_SERVICES,
	isJsonStringStandardResponseWithErrors,
	AccessScope,
	toScopeAsUrlParameter,
} from 'type-declarations';
import { resetPartnerUsers } from './slice';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;

async function getPartnerUsers(activeScope: AccessScope): Promise<Array<RhymeUser>> {
	const response = await get({
		apiName: rhymeApiUrl,
		path: `user?${toScopeAsUrlParameter(activeScope)}`,
	}).response;
	if (response.statusCode === 200) {
		return response.body.json() as unknown as Array<RhymeUser>;
	}
	return [];
}

export const fetchPartnerUsers = createAsyncThunk(
	'partner-users/fetchPartnerUser',
	async (_, { getState, dispatch }) => {
		const appState = getState() as any;
		const activeScope = appState.user.activeScope as AccessScope;
		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: `user?${toScopeAsUrlParameter(activeScope)}`,
			}).response;
			const data = body.json() as unknown as Array<RhymeUser>;
			return data;
		} catch (error) {
			dispatch(resetPartnerUsers());
			throw new Error(`Fetch partner user failed with error: ${error}`);
		}
	}
);

export const updatePartnerUser = createAsyncThunk(
	'partner-users/updatePartnerUser',
	async ({ user }: { user: RhymeUser }, { getState, rejectWithValue }) => {
		const appState = getState() as any;
		const activeScope = appState.user.activeScope as AccessScope;
		const params = {
			body: {
				...user,
			},
		};
		try {
			await patch({
				apiName: rhymeApiUrl,
				path: `user/${user.userName}?${toScopeAsUrlParameter(activeScope)}`,
				options: params,
			}).response;
			const users = await getPartnerUsers(activeScope);
			return users;
		} catch (error) {
			if (
				error instanceof ApiError &&
				isJsonStringStandardResponseWithErrors(error.response?.body)
			) {
				return rejectWithValue(JSON.parse(error.response?.body || '{}'));
			}
			throw error;
		}
	}
);

export const createPartnerUser = createAsyncThunk(
	'partner-user/createPartnerUser',
	async ({ user }: { user: RhymeUser }, { getState, rejectWithValue }) => {
		const appState = getState() as any;
		const activeScope = appState.user.activeScope as AccessScope;
		const params = {
			body: {
				...user,
			},
		};
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `user/${user.userName}?${toScopeAsUrlParameter(activeScope)}`,
				options: params,
			}).response;
			const users = await getPartnerUsers(activeScope);
			return users;
		} catch (error) {
			if (
				error instanceof ApiError &&
				isJsonStringStandardResponseWithErrors(error.response?.body)
			) {
				return rejectWithValue(JSON.parse(error.response?.body || '{}'));
			}
			throw error;
		}
	}
);

export const deletePartnerUser = createAsyncThunk(
	'partner-user/deletePartnerUser',
	async ({ user }: { user: RhymeUser }, { getState }) => {
		const appState = getState() as any;
		const activeScope = appState.user.activeScope as AccessScope;
		const email = user.userName ?? '';
		await del({
			apiName: rhymeApiUrl,
			path: `user/${email}?${toScopeAsUrlParameter(activeScope)}`,
		}).response;
		return await getPartnerUsers(activeScope);
	}
);

export const resendPartnerUserInvite = createAsyncThunk(
	'partner-user/resendPartnerUserInvite',
	async ({ userName }: { userName: string }, { getState }) => {
		const appState = getState() as any;
		const activeScope = appState.user.activeScope as AccessScope;
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `user/${userName}/resend-invitation?${toScopeAsUrlParameter(activeScope)}`,
			}).response;
			SnackbarManager.show({
				message: 'Confirmation email sent to user',
				type: 'success',
			});
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to resend user invite.',
				type: 'error',
			});
			throw new Error(`Resend partner user invite failed with error: ${error}`);
		}
	}
);

export const resetMfaDeviceToEmail = createAsyncThunk(
	'partner-user/resetMfaDevice',
	async ({ user }: { user: RhymeUser }, { getState }) => {
		const email = user.userName ?? '';
		try {
			await patch({
				apiName: rhymeApiUrl,
				path: `user/${email}/mfa-method/email`,
			}).response;
			SnackbarManager.show({
				message: 'Recovery code was emailed to the user',
				type: 'success',
			});
		} catch (error) {
			SnackbarManager.show({
				message: 'Failed to reset mfa device.',
				type: 'error',
			});
			throw new Error(`Reset MFA device failed with error: ${error}`);
		}
	}
);
