import { useAppSelector } from '@app/hooks/useReduxToolkit';
import { selectUserFullName, UserState } from 'state-services';
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { FC } from 'react';
import SelectOrganizationMenu from './SelectOrganizationMenu';
import { useNavigate } from 'react-router-dom';
import { SessionTimer } from 'rhyme-component-library';
import { stringAvatar } from 'acessibility-helpers/src/uiHelpers';
import { signOut as SignOut } from 'aws-amplify/auth';
import { useActivePartnerEntity } from '@app/hooks/useActivePartnerEntity';

const UserCard: FC = () => {
	const userState = useAppSelector<UserState>((state) => state.user);
	const activePartnerEntity = useActivePartnerEntity();

	const userFullName = selectUserFullName(userState);
	let { name: partnerEntityName } = activePartnerEntity;
	const navigate = useNavigate();

	const signOut = (): void => {
		SessionTimer.stopSessionTimer();
		navigate('/');
		SignOut();
	};

	if (partnerEntityName.length >= 15) {
		partnerEntityName = partnerEntityName.substring(0, 17) + '...';
	}

	return (
		<Card variant="outlined">
			<CardHeader
				avatar={<Avatar {...stringAvatar(userFullName)} />}
				title={userFullName}
				subheader={partnerEntityName}
			/>
			<Divider />
			<CardContent sx={{ padding: 0 }}>
				<List dense>
					<SelectOrganizationMenu></SelectOrganizationMenu>
					<ListItem>
						<ListItemButton onClick={signOut} dense>
							<ListItemText>Sign Out</ListItemText>
						</ListItemButton>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
};

export default UserCard;
