/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError, get, post, put } from 'aws-amplify/api';
import { SnackbarManager } from 'rhyme-component-library';
import {
	DraftModel,
	Referral,
	ReferralDiffResponse,
	RHYME_API_SERVICES,
	ValidationError,
} from 'type-declarations';
import { fixBackendValidationErrorIssues } from './utilities';
import * as Sentry from '@sentry/react';

const rhymeApiUrl = RHYME_API_SERVICES.RhymeApi;
const validResponseCodes = [200, 404];
const validConfirmSubmissionCodes = [200, 409, 422, 424];
const validValidateReferralCodes = [200, 400, 409, 422, 424];
const systemErrorCodes = [500, 503];

export const fetchDraft = createAsyncThunk(
	'draft-thunk/fetchDraftThunk',
	async ({ activeScopeId, id }: { activeScopeId: string; id: string }) => {
		const getUrl = id.startsWith('C-')
			? `organization/${activeScopeId}/draft?caseId=${id}`
			: `organization/${activeScopeId}/draft/${id}`;

		try {
			const { body } = await get({
				apiName: rhymeApiUrl,
				path: getUrl,
			}).response;
			const data = (await body.json()) as unknown as DraftModel;
			const partnerCaseID = data.partnerCaseId;
			data.errors = fixBackendValidationErrorIssues(data.errors);
			Sentry.setTag('partnerCaseID', partnerCaseID);
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validResponseCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        draft service expects${validResponseCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			Sentry.captureException(error);
			throw new Error(`Fetch draft failed with error: ${error}`);
		}
	}
);

export const confirmSubmission = createAsyncThunk(
	'draft-thunk/confirmSubmissionThunk',
	async (draft: DraftModel) => {
		const options = {
			body: { ...draft.referral } as unknown as FormData,
		};
		try {
			await post({
				apiName: rhymeApiUrl,
				path: `organization/${draft.organizationId}/draft/${draft.partnerCaseId}/submit?notifyOnError=true`,
				options,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validConfirmSubmissionCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else if (systemErrorCodes.includes(statusCode)) {
					SnackbarManager.show({
						message: 'There was an issue submitting the case. Please contact support.',
						type: 'error',
					});
				} else {
					SnackbarManager.show({
						message: 'Failed to confirm submission.',
						type: 'error',
					});
					throw new Error(
						`Response status of ${statusCode}, 
        draft service expects${validConfirmSubmissionCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			SnackbarManager.show({
				message: 'Failed to confirm submission.',
				type: 'error',
			});
			throw new Error(`Draft confrim submission failed with error: ${error}`);
		}
	}
);

/**
Validates the referral on a Draft object. Returns only ValidationErrors. If no errors are returned,
referral can be assumed valid.
*/
export const validateReferral = createAsyncThunk(
	'draft-thunk/validateReferral',
	async (draft: DraftModel) => {
		const options = {
			body: { ...draft.referral } as unknown as FormData,
		};
		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: `organization/${draft.organizationId}/draft/${draft.partnerCaseId}/validate?setDefaults=false`,
				options,
			}).response;
			const data = await body.json();
			return data;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validValidateReferralCodes.includes(statusCode) && body) {
					const response = JSON.parse(body);
					const validationErrors = response.errors as ValidationError[];
					return fixBackendValidationErrorIssues(validationErrors);
				} else {
					console.error('Unexpected response from DraftService for ValidateReferral', body);
					SnackbarManager.show({
						message: 'Failed to validate the case.',
						type: 'error',
					});
					return [] as ValidationError[];
				}
			}
			return [] as ValidationError[];
		}
	}
);

/**
 * saves referral on a draft object
 */

export const saveReferral = createAsyncThunk(
	'draft-thunk/saveReferral',
	async (draft: DraftModel) => {
		const options = {
			body: { ...draft.referral } as unknown as FormData,
		};
		try {
			await put({
				apiName: rhymeApiUrl,
				path: `organization/${draft.organizationId}/draft/${draft.partnerCaseId}`,
				options,
			}).response;
		} catch (error) {
			if (error instanceof ApiError && error.response) {
				const { statusCode, body } = error.response;
				if (validValidateReferralCodes.includes(statusCode) && body) {
					return JSON.parse(body);
				} else {
					throw new Error(
						`Response status of ${statusCode}, 
        draft service expects${validValidateReferralCodes.reduce(
					(acc, code, i) => `${acc.toString()}${i >= 1 ? ',' : ''} ${code.toString()}`,
					''
				)}.`
					);
				}
			}
			SnackbarManager.show({
				message: 'Failed to save the case.',
				type: 'error',
			});
			throw new Error(`Save referral failed with error: ${error}`);
		}
	}
);

/**
 * validate referral
 * and waits for no new calls for 1500 milliseconds
 * before calling save referral
 * @param {object } DraftModel
 * @returns {ValidationError[]}
 */

export const validateAndSaveReferral = createAsyncThunk(
	'draft-thunk/validateAndSaveReferral',
	async (draft: DraftModel, { dispatch }) => {
		const [_, draftValidation] = await Promise.all([
			dispatch(saveReferral(draft)),
			dispatch(validateReferral(draft)).unwrap(),
		]);
		return draftValidation;
	}
);

export const getReferralDiff = createAsyncThunk(
	'draft-thunk/getReferralDiff',
	async ({
		organizationId,
		partnerCaseId,
		referral,
	}: {
		organizationId: string;
		partnerCaseId: string;
		referral: Referral;
	}) => {
		const getUrl = `organization/${organizationId}/draft/${partnerCaseId}/referral/diff`;
		const options = {
			body: { ...referral } as unknown as FormData,
		};
		try {
			const { body } = await post({
				apiName: rhymeApiUrl,
				path: getUrl,
				options: options,
			}).response;
			const data = (await body.json()) as unknown as ReferralDiffResponse;
			return data;
		} catch (error) {
			throw new Error(`Getting referral diff failed with error: ${error}`);
		}
	}
);
