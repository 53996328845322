import {
	DashboardDetails,
	EmbedDashboardUrlResponse,
	EntityType,
	PartnerEntity,
} from 'type-declarations';
import { DashboardContentOptions, Parameter } from 'amazon-quicksight-embedding-sdk';

export const getAndSortDashboardsFromEmbedUrls = (
	embedUrls: EmbedDashboardUrlResponse[]
): DashboardDetails[] => {
	return embedUrls
		.flatMap((embed) => embed.dashboards)
		.sort((a: DashboardDetails, b: DashboardDetails) => {
			return b.priority - a.priority;
		});
};

//We have to set default filters so they work correctly.
export function getDefaultContentOptions(
	activeEntity: PartnerEntity,
	selectedPartnerEntityIds: string[]
): DashboardContentOptions {
	const parameters = [] as Parameter[];
	switch (activeEntity.partnerType) {
		case EntityType.DELEGATED_BENEFIT_MANAGER:
			parameters.push({
				Name: 'DelegatedBenefitManager',
				Values: selectedPartnerEntityIds,
			});
			break;
		case EntityType.PAYER_INSTITUTION:
			parameters.push(
				{
					Name: 'Payer2',
					Values: selectedPartnerEntityIds,
				},
				{
					Name: 'Payer',
					Values: [activeEntity.name],
				}
			);
			break;
		case EntityType.PROVIDER_ORGANIZATION:
			parameters.push(
				{
					Name: 'Provider2',
					Values: selectedPartnerEntityIds,
				},
				{
					Name: 'Provider',
					Values: [activeEntity.name],
				}
			);
			break;
		default:
			break;
	}
	return {
		parameters,
	};
}
