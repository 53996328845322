import { CrudDialogState, CrudType } from './models';
import { PartnerUsersState } from './slice';

//Selectors for PartnerUsersState
//Selectors concepts explained here: https://redux.js.org/usage/deriving-data-selectors#calculating-derived-data-with-selectors

/**
 * Returns the dialog state for the given CrudType
 */
export const selectDialogStateByCrudType = (
	state: PartnerUsersState,
	crudType: CrudType
): CrudDialogState => state.dialogs.find((d) => d.crudType === crudType)!;

/**
 * Returns the dialog state for the "Add New User" dialog
 */
export const selectAddDialogState = (state: PartnerUsersState): CrudDialogState =>
	selectDialogStateByCrudType(state, CrudType.Create);

/**
 * Returns the dialog state for the "Edit User" dialog
 */
export const selectEditDialogState = (state: PartnerUsersState): CrudDialogState =>
	selectDialogStateByCrudType(state, CrudType.Update);

/**
 * Returns the dialog state for the "Delete User" dialog
 */
export const selectDeleteDialogState = (state: PartnerUsersState): CrudDialogState =>
	selectDialogStateByCrudType(state, CrudType.Delete);

/**
 * Returns the dialog state for the 'Reset MFA to email" dialog
 */
export const selectResetMfaDialogState = (state: PartnerUsersState): CrudDialogState =>
	selectDialogStateByCrudType(state, CrudType.Reset);
